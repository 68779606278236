import React from "react";
import {Card, CardActions, CardActionArea, CardContent, Grid, makeStyles} from '@material-ui/core/';
import {Container} from '@airbus/components-react';
import { BrowserRouter, withRouter } from 'react-router-dom';

// import logo from '../../../public/images/AIRBUS_WHITE2.png' // relative path to image 



const useStyles = makeStyles({    // card is for  logo beside header
  card: {
    // minWidth: props => props.mobile? "3rem":"9rem",
    // maxWidth: props => props.mobile? "3rem":"9rem",
    // width:props => props.mobile? "2rem":"9rem",
    // height:props => props.mobile?'3rem':"6.5rem"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    // marginBottom: 12,
  },
  image:
  {
    // marginTop:'0.5rem', 
    // height:props => props.mobile? "1rem":"2.5rem", 
    // width:props => props.mobile? "4rem":"6rem",
    height:"3rem",
    width:"6.3rem",
    // marginLeft:'0.5rem'
  }
});



function SimpleCard(props) {
  const classes = useStyles();

  const onClickHandler=(e,index)=>{ //for logo routing

        if(index==0) props.history.push( "/landing");
        props.setTab(-1);
  }

  return (
  
    <Grid align-items-xs-center justify-xs-flex-end >
      <BrowserRouter> 
        <Card>
          {/* <CardActionArea> */}
            {/* <Container  style ={{backgroundColor: '#00205b'}} className={classes.card}>    */}
            {/* <Container  style ={{backgroundColor: '#FFFFFF',height:"4rem", width:"8.1rem", marginBottom:"1.2rem",paddingRight:"1rem"}} >    */}

              <CardContent onClick= {e=>onClickHandler(e,0)}  style ={{backgroundColor: '#00205b',height:"5.7rem", width:"8rem"}}>
                <div style ={{backgroundColor: '#FFFFFF'}}>
                <img className={classes.image}   //centers airbus logo
                 onClick= {e=>onClickHandler(e,0)} src='/images/AIRBUS_WHITE2.png'  />
                           </div>
                <CardActions/>        
              </CardContent>
            {/* </Container>
          </CardActionArea> */}
        </Card>
      </BrowserRouter> 
    </Grid>

  );
}

export default withRouter(SimpleCard);