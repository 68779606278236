import L from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RoomIcon from '@material-ui/icons/Room';
import PinDropIcon from '@material-ui/icons/PinDrop';

///Used to apply Material UI icons as leaflet icons.

// export const defaultIcon = L.icon({
//     //icon: 'stop_circle',            // Name of Material icon
//     iconColor: 'white',              // Material icon color (could be rgba, hex, html name...)
//     markerColor: 'rgba(42, 129, 203, 1)',  // Marker fill color
//     outlineColor: 'black',            // Marker outline color
//     outlineWidth: 1,                   // Marker outline width 
//     iconSize: [31, 42]                 // Width and height of the icon
//   })

export const defaultIcon = L.divIcon({
    className: 'default-div-icon',
    html: ReactDOMServer.renderToString( <RoomIcon style={{ width: '31',height: '42', color: 'rgba(42, 129, 203, 1)' }} /> ),// Width and height of material icon, colour of icon
    iconSize: [31, 42]                 // Width and height of the icon allocated by leaflet
});

export const movingIcon = L.divIcon({
    className: 'moving-div-icon',
    html: ReactDOMServer.renderToString( <RoomIcon style={{ width: '31',height: '42', color: 'rgba(251, 133, 14, 1)' }} /> ),// Width and height of material icon, colour of icon
    iconSize: [31, 42]                 // Width and height of the icon allocated by leaflet
});

export const unavailableIcon = L.divIcon({
    className: 'unavailable-div-icon',
    html: ReactDOMServer.renderToString( <RoomIcon style={{ width: '31',height: '42', color: 'rgba(224, 0, 0, 1)' }} /> ),// Width and height of material icon, colour of icon
    iconSize: [31, 42]                 // Width and height of the icon allocated by leaflet
});

export const selectedIcon = L.divIcon({
    className: 'selected-div-icon',
    html:ReactDOMServer.renderToString( <PinDropIcon style={{ width: '31',height: '42', color: 'rgba(0, 63, 117, 1)' }} /> ),// Width and height of material icon, colour of icon
    iconSize: [31, 42]                 // Width and height of the icon allocated by leaflet
});

