import React, { Component } from "react";
import { getTrackers, getZoneData, getAssets } from "../components/DownloadManager";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BuildIcon from '@material-ui/icons/Build';
import UpdateIcon from '@material-ui/icons/Update';
import SearchIcon from '@material-ui/icons/Search';
import {
  Accordion,
  MenuItem,
  Select,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  ListSubheader
} from "@material-ui/core";
import { Banner, Button, Stack, Wrap } from "@airbus/components-react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { withRouter} from 'react-router-dom';
import CropFreeIcon from "@material-ui/icons/CropFree";
import QrReader from 'modern-react-qr-reader'
import LoopIcon from '@material-ui/icons/Loop';
import { format } from 'date-fns'
import validator from 'validator';
import { getAssetDefect } from "../components/DownloadManager";
import Autocomplete from '@material-ui/lab/Autocomplete';



// const styles = (theme) => ({
//   overrides: {
//     MuiButton: {
//       textPrimary: {
//         color: '#80d8ff',
//       }
//     },
//   }
    // root: {
    //   "& > *": {
    //     margin: theme.spacing(1),
      
    //   },

     
  
    //   formControl: {
    //     margin: theme.spacing(1),
    //     minWidth: 120,
    //   },
    //   selectEmpty: {
    //     marginTop: theme.spacing(2),
    //   },
    // },
    // infoSection: {
    //   flexDirection: (props) => (props.isMobile ? "column" : "row"),
    //   display: "flex",
    //   justifyContent: "space-between",
    // },
// })  


class ReportDefect extends Component {

  state = {
    assets:null,
    assetID: "",
    assetDefectID:"",
    assetDefectFindings:"",
    assetDefectName:"",
    assetDefectType:[],
    assetRepairStatusType:[],
    assetRepairStatus: null,
    assetRepairStatusColor:"default",
    assetSerialNo:"",
    assetName: "",
    assetDefectReportedBy:"",
    assetDefectRepairedBy:"",
    assetDefectLastReportedDate:null,
    assetDefectLastRepairedDate:null,
    isLoading:false,
    repairID: "",
    repairAction: "",
    dialogOpen: false,
    data:null,
    qrError: false,
    createDefect:null,
    viewDefect: null,
    showSubmitDefectButton:null,
    showUpdateDefectButton:null,
    assetDefectData:null,
    allAssetDefectData:null,
    repairedByError:null,
    isDoneUpdated:null,
    isDoneSubmitted:null,
    reportedByError:null,
    hasScanned:null,
    assetDefectButton:null,
    updateDefectButton:null,
    dateError:null,
    enableReset:false,
    combinedDefects:[]

  }

  componentDidMount() {
    // JSON.serialize("S/N:A-1230A")
    // this.getTrackerData();
    this.getAssetData();
    this.getAssetDefectData();
    this.resetForm()
    //this.getZoneData()

 

  }
  getAssetData = () => {
    getAssets(100, 2, (res) => {
    this.setState({assets:res})
    });
    this.getAssetDefectType()

  };


  getAssetDefectData = () => {
    getAssetDefect( (res) => {
    this.setState({allAssetDefectData:res})
    });
  };

  resetForm = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    this.setState({
      assetID: "",
      assetDefectID:"",
      assetDefectName:"",
      assetDefectFindings:"",
      assetName: "",
      assetDefectLastRepairedDate:null,
      assetDefectLastReportedDate:null,
      assetDefectRepairedBy:"",
      assetDefectReportedBy:"",
      assetRepairStatus:"",
      assetRepairStatusColor:"default",
      assetSerialNo:"",
      createDefect:null,
      viewDefect: null,
      isLoading:false,
      qrError: null,
      showSubmitDefectButton:null,
      showUpdateDefectButton:null,
      assetDefectData:null,
      assetRepairStatusType: [],
      repairedByError:null,
      isDoneUpdated:null,
      isDoneSubmitted:null,
      reportedByError:null,
      hasScanned:null,
      assetDefectButton:null,
      updateDefectButton:null,
      dateError:null,
      enableReset:false,
      data:null,
      allAssetDefectData:null,
      combinedDefects:[]
    });
    this.getAssetDefectData();

  };


  openDialog = () => {
    this.resetForm();
    this.setState({ dialogOpen: true,qrError:false });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  // createAssetDefect=()=>{
  //   this.setState({viewDefect:false, showUpdateDefectButton:false, showSubmitDefectButton:true})
  // }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },()=>{this.handleEmptyfields();this.handleUpdateEmptyfields()})
  }


  handleSNChange = (event) => {
    this.resetForm()
    this.setState({ [event.target.name]: event.target.value },()=>{this.handleEmptyfields();this.handleUpdateEmptyfields()})
  }

  // handle date input 
  handleRepairedDateChange = (date) => {
    this.setState({assetDefectLastRepairedDate:date.toISOString()},()=>{this.handleUpdateEmptyfields()})
  };

 // handle email regex validation
  handleReportedDateChange = (date) => {
    this.setState({assetDefectLastReportedDate:date.toISOString()})
  };

  // handle email regex validation
  handleReportedEmailChange=(event)=>{
    // const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexp = validator.isEmail( event.target.value)

    this.setState({ [event.target.name]: event.target.value,reportedByError:!regexp},()=>{this.handleEmptyfields()})
  

    return regexp;
  }


  handleRepairedEmailChange=(event)=>{
    // const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexp = validator.isEmail( event.target.value)
this.setState({ [event.target.name]: event.target.value,repairedByError:!regexp},()=>{this.handleUpdateEmptyfields()})

    return regexp;
  }

  //retrieve list of asset repair statuses
  getAssetRepairStatus=()=>{
    axios.get(process.env.REACT_APP_BASE_URL + '/asset_repair_status') //pulling data from Inspection method
    .then(response => {
      console.log(response)
      this.setState({
        assetRepairStatusType: response.data //set incoming data into data array
      })
    }).catch(this.handleErrors)
    return 1;
  
  }

  // render list of asset repair status options
  renderAssetRepairStatusOptions() {

    //mapping data array to get each set of data
        // console.log(dt);
        return this.state.assetRepairStatusType.map(item => { //mapping each set of data to get NDT_Story Value only
          return (
            <MenuItem
              label={item.iotassetrepairstatusname}
              value={item.iotassetrepairstatusname}
              name={item.iotassetrepairstatusname}
              key={item.iotassetrepairstatusid}
              >
              {item.iotassetrepairstatusname}
           </MenuItem>
            
          );
        })
     
    }
  


// convert s/n from qr code into input string
parseJSONtoJSObject=(qrString)=>{

  //  var regexToMatch = /(?<=S\/N:).*?(?=\s)/; 
   var regexToMatch = /.*S\/N:+(.*)\s/; 
   var myArray = qrString.match(regexToMatch)
   if(myArray && myArray[1]){

//  console.log(result)
    // this.getAssetDefectDetails(result.iotassetserialno)
 this.getAssetDefectDetails(myArray[1])
  // this.getAssetDetails("A-1253A")

  return 1;
}
  else{
    this.setState({qrError:true, dialogOpen:false, hasScanned:true})
    return 1;
   }

}

// retrieve list of asset defect types
  getAssetDefectType=()=>{
    axios.get(process.env.REACT_APP_BASE_URL + '/asset_defect_type', {})
    .then(response => {
      //console.log(response.data.Result)
      this.setState({
        assetDefectType: response.data 
      },()=>{this.handleSortD()})
    }
    ).catch(this.handleErrors)
  
  }

  handleSortD=()=>{
    // console.log(this.state.assetDefectType)
   var npa= this.state.assetDefectType.filter(function(yes){

      return yes.iotassetdefecttypeid<5 ;
      

    })
    // console.log(npa)
  var  npa1= npa.map(v => ({...v, category: "Non-powered Assets"}))
  // console.log(npa1)

  var pa= this.state.assetDefectType.filter(function(yes){

    return 6<=yes.iotassetdefecttypeid || yes.iotassetdefecttypeid==3;

  })
  // console.log(pa)
  var  pa1= pa.map(v => ({...v, category: "Powered Assets"}))
  // console.log(pa1)
  var other= this.state.assetDefectType.filter(function(yes){

    return yes.iotassetdefecttypeid==5 ;

  })
  // console.log(other)
  var  both= other.map(v => ({...v, category: 'Non Powered/Powered Assets'}))



var combinedDefects= npa1.concat(pa1,both)
  // console.log(combinedDefects)
return  this.setState({combinedDefects})

  }

  // render asset defect type dropdown list
  renderAssetDefectTypeOptions=()=>{
    return this.state.assetDefectType.map(item => { 
   
     
 return (

  
        <MenuItem
          label={item.iotassetdefecttypeid}
          value={item.iotassetdefecttypename}
          name={item.iotassetdefecttypename}
          key={item.iotassetdefecttypeid}>

          {item.iotassetdefecttypename}
       </MenuItem>
       
      );
      
    })
 
}  
// search through list of assets matching input serial no.
getAssetDefectDetails=(iotassetserialno)=>
{
  var asset =this.state.assets.find(x => x.iotassetserialno === iotassetserialno.toUpperCase())

  // console.log(assetName)

   if (asset)
  {
    var assetID= asset.iotassetid
    var assetName= asset.iotassetname
    var assetSerialNo =asset.iotassetserialno
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/asset`, {
      headers: {
        "x-api-key": process.env.REACT_APP_BASE_API_KEY,
      },
      params: {
        iotassetid: assetID,
        // join:1
      },
    })
    .then((res) => {
      this.setState({ data: res.data,assetID,assetName,assetSerialNo, dialogOpen: false, enableReset:true,hasScanned:true}, () => {this.toPopulateAssetDefect(assetID);});
  
      return 1;
    })
    .catch((err) => {
      console.log(err.response);
      return 1;
    });
  }
  else
  {
    console.log("asset doesn't exist")
    this.setState({dialogOpen: false,qrError:true,hasScanned:true})
   return -1;
   
  }

};

//retrieve asset defects from defect table
toPopulateAssetDefect = (assetID) => {
  if (assetID == null || undefined) {
    return 1;
  } else {

    axios.get(`${process.env.REACT_APP_BASE_URL}/asset_defect`, {
      headers: {
        "x-api-key": process.env.REACT_APP_BASE_API_KEY,
      },
      params: {
        iotassetid: assetID,
        // join:1
      },
    })
    .then((res) => {
    this.setState({ assetDefectData: res.data, dialogOpen: false, hasScanned:true}, () => {this.processDefectData(this.state.assetDefectData)});
  
      return 1;
    })
    .catch((err) => {
      this.newAssetDefect(this.state.assetID)
      console.log(err.response);
      
      return 1;
    });
  }
};

newAssetDefect=(assetID)=>{
  if (assetID == null || undefined) {
    return 1;
  } else {

    // axios.get(`${process.env.REACT_APP_BASE_URL}/asset_defect`, {
    //   headers: {
    //     "x-api-key": process.env.REACT_APP_BASE_API_KEY,
    //   },
    //   params: {
    //     // join:1
    //   },
    // })
    // .then((res) => {
      var todayDate=  format(new Date(), 'yyyy-MM-dd kk:mm:ss')
 this.setState({createDefect:true,showSubmitDefectButton:true,assetDefectLastReportedDate:todayDate},()=>{this.processDefectData(this.state.allAssetDefectData)})
  
      return 1;
    }
  


}

// populate ui fields after asset defect api call
processDefectData=(assetDefectData)=>{
if(assetDefectData!=null){
  var assetRepairStatusID= assetDefectData[0].iotassetrepairstatusid
  if (assetRepairStatusID != 3 ) {  //change to 3 for pcp
        console.log("update defect")


        function checkRepairStatusColor(assetDefectData){
          return (assetDefectData[0].iotassetrepairstatusid !=1  ?  "secondary":"primary")
        }

// logic to update defect fields
// var todayDate = new Date()

var assetDefectID = assetDefectData[0].iotassetdefectid
var assetDefectName= assetDefectData[0].iotassetdefecttypename
var assetDefectReportedBy = assetDefectData[0].iotassetdefectreportedbyuserid
var assetDefectReportedDateTime= assetDefectData[0].iotassetdefectreporteddatetime
var assetDefectRepairedBy=assetDefectData[0].iotassetdefectrepairedbyuserid
var assetDefectRepairAction=assetDefectData[0].iotassetdefectrepairedaction

var assetDefectRepairDateTime 
assetDefectData[0].iotassetdefectrepaireddatetime !=null ?  assetDefectRepairDateTime= assetDefectData[0].iotassetdefectrepaireddatetime : assetDefectRepairDateTime = (new Date()).toISOString()

var assetRepairStatus=assetDefectData[0].iotassetrepairstatusname
var assetRepairStatusColor = checkRepairStatusColor(assetDefectData)
var assetDefectFindings= assetDefectData[0].iotassetdefectfindings
 this.setState({assetDefectID, assetDefectName, assetDefectReportedBy,assetDefectLastReportedDate:assetDefectReportedDateTime, 
  assetDefectRepairedBy,repairAction:assetDefectRepairAction,assetDefectLastRepairedDate:assetDefectRepairDateTime,assetRepairStatus,assetRepairStatusColor, assetDefectFindings, viewDefect:true, createDefect:false,showUpdateDefectButton:true, showSubmitDefectButton:false})

  }
  else{

console.log("create defect")
this.getAssetDefectData()
var createAssetDefectID = (this.state.allAssetDefectData[0].iotassetdefectid) + 1
console.log(createAssetDefectID)
var todayDate=  format(new Date(), 'yyyy-MM-dd kk:mm:ss')
this.setState({assetDefectID:createAssetDefectID,viewDefect:false, createDefect:true, showUpdateDefectButton:false,showSubmitDefectButton:true, assetDefectLastReportedDate:todayDate})
  }

}

}

// api call to update asset defect
updateAssetDefect=()=>{
  window.scrollTo({top: 0, behavior: 'smooth'})
this.setState({isLoading:true})
console.log(this.state.assetDefectID)
  axios.patch(`${process.env.REACT_APP_BASE_URL}/asset_defect`, [

    {
		iotassetid:this.state.assetID,
    iotassetdefectid:this.state.assetDefectID,
		iotassetdefectfindings:this.state.assetDefectFindings,
		iotassetdefecttypename:this.state.assetDefectName,
    iotassetdefectreportedbyuserid:this.state.assetDefectReportedBy,
    iotassetdefectreporteddatetime: String(format(new Date(this.state.assetDefectLastReportedDate), 'yyyy-MM-dd kk:mm:ss')),
		iotassetdefectrepairedbyuserid:this.state.assetDefectRepairedBy,
		iotassetdefectrepaireddatetime:String(format(new Date(this.state.assetDefectLastRepairedDate), 'yyyy-MM-dd kk:mm:ss')),
		iotassetdefectrepairedaction: this.state.repairAction,
    iotassetrepairstatusname: this.state.assetRepairStatus
      }
  ]
  )
  .then((res) => {
    this.setState({isDoneUpdated:true})
    setTimeout(()=>{this.setState({isDoneUpdated:false},()=>{this.resetForm()})},5000)

    return 1;
  })
  .catch((err) => {
    console.log(err.response);
    return 1;
  });
}


// api call to create new asset defect
submitAssetDefect=()=>{
  window.scrollTo({top: 0, behavior: 'smooth'})
  this.setState({isLoading:true})
  axios.post(`${process.env.REACT_APP_BASE_URL}/asset_defect`, [

    {
    iotassetid:this.state.assetID,
		iotassetdefectfindings:this.state.assetDefectFindings,
		iotassetdefecttypename:this.state.assetDefectName,
    iotassetdefectreportedbyuserid:this.state.assetDefectReportedBy,
    iotassetdefectreporteddatetime: String(format(new Date(this.state.assetDefectLastReportedDate), 'yyyy-MM-dd kk:mm:ss')),
    iotassetrepairstatusname: "Fault reported"
    
  }
  ]
  )
  .then((res) => {
    this.setState({isDoneSubmitted:true})
    setTimeout(()=>{this.setState({isDoneSubmitted:false},()=>{this.resetForm()})},5000)

    return 1;
  })
  .catch((err) => {
    console.log(err.response);
    return 1;
  });
}

handleEmptyfields=()=>{   // validation checks for valid fields to enable/disable submit button

  if ((this.state.assetDefectName != "")  && (this.state.assetDefectReportedBy != "") && (!this.state.reportedByError))
  {

    this.setState({assetDefectButton:true})


}
else{this.setState({assetDefectButton:false})}


}

handleUpdateEmptyfields=()=>{  // validation checks for valid fields to enable/disable update button

  if ((this.state.assetDefectRepairedBy!== "")&&(this.state.assetDefectRepairedBy!= null)  &&(this.state.repairAction!= null) && (this.state.repairAction !== "")&& (this.state.assetDefectLastRepairedDate!== "") &&(!this.state.dateError) &&(!this.state.repairedByError))
  {
    this.setState({updateDefectButton:true})


}
else{this.setState({updateDefectButton:false})}


}

handleDateError=(error)=>{   //check if date is valid to enable/disable update button 

if (error) {
this.setState({dateError:true},()=>{this.handleUpdateEmptyfields()})
return 1
}
else
{
  this.setState({dateError:false},()=>{this.handleUpdateEmptyfields()})
  return 1
}

}


render() {
// this.handleSortD()
    return (
        <div>
{/* { this.state.qrError && <Banner variant="error">Error Scan</Banner>} */}
{ this.state.qrError && <Banner variant="error">Invalid Asset</Banner>}
{ this.state.isDoneUpdated && <Banner variant="success">Asset {this.state.assetSerialNo} defect {this.state.assetDefectID} has been updated </Banner>}
{ this.state.isDoneSubmitted && <Banner variant="success">Asset {this.state.assetSerialNo} defect has been created </Banner>}

<h2>QR Scanning</h2>
<div style={{marginTop:'1rem'}}>

            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant={"h5"}
                  color="primary"
                >
                QR Scan (Asset Defect Report)
                </Typography>
              </AccordionSummary>

          <AccordionDetails>
              <Stack spacing="1-x">


              <Wrap as="ul"spacing="1-x">
                <FormControl
                    variant="outlined"
                    // required={true}
                    error={this.state.assetIDError}
                  >

                    
                    <InputLabel htmlFor="component-outlined">
                      Asset ID
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetID"
                      value={this.state.assetID}

                 
                    />
                  </FormControl>

                



                  <FormControl
                    variant="outlined"
                    
                    error={this.state.assetNameError}
                  > 
<InputLabel htmlFor="component-outlined">
                      Asset Name
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetName"
                      value={this.state.assetName}
                      fullWidth
                      multiline
                      rowsMax="3"
                    />
                  </FormControl>

                  <FormControl variant="outlined" required={true}>
                    <InputLabel htmlFor="component-outlined">
                      Asset Serial No.
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetSerialNo"
                      value={this.state.assetSerialNo}
                      onChange={this.handleSNChange}

                    />
                  </FormControl>


                  <Button
                    variant="primary"
                    style={{ marginTop: "1rem"}}
                    onClick={this.openDialog}
                    icon={<CropFreeIcon />}
                    disabled={this.state.assetSerialNo}
                  >
                    Scan QR
                  </Button>
                  

                  <Button
                    variant="primary"
                    style={{ marginTop: "1rem" }}
                    onClick={()=>{   this.getAssetData();  this.getAssetDefectData();var assetSN =this.state.assetSerialNo
                      // console.log(assetID)
                      if(assetSN){this.getAssetDefectDetails(assetSN)} else(this.setState({qrError:true,hasScanned:true,enableReset:true})); return 1; }}
                    icon={<SearchIcon />}
                    disabled={!this.state.assetSerialNo}
                  >
                    Manual Search
                  </Button>

                 {/* { this.state.createDefect&& <Button
                           style={{ marginTop: "1rem", marginLeft: "1rem" }}
                           icon={<BuildIcon />}
       type="submit"
       onClick={this.createAssetDefect}
       variant="success"
       disabled={!this.state.createDefect}
     >
      Create Defect
     </Button>
} */}
                  </Wrap>
                 
             
                  { (this.state.hasScanned && this.state.assetSerialNo && !this.state.qrError ) &&
                 <Wrap as="ul" spacing="1-and-a-half-x" padding="1-x">
               <FormControl
                    variant="outlined"
                    required={true}
                  >

                    
                   <InputLabel htmlFor="component-outlined">
                       Asset Defect ID
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetDefectID"
                      value={this.state.assetDefectID}
                      readOnly
                      disabled={this.state.createDefect}
                      onChange={this.handleChange}
                    />
                  </FormControl>

                  {/* <FormControl
                    variant="outlined"
                    required={true}
                    error={this.state.assetDefectNameError}
                  >

                    
                    <InputLabel htmlFor="component-outlined">
                       Asset Defect Name
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="repairID"
                      value={this.state.assetDefectName}
                    />
                  </FormControl> */}



<FormControl variant="outlined">
  <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
  {/* <Select style={{minWidth:'12.5rem'}}
     onOpen={ this.getAssetDefectType}
    value={this.state.assetDefectName}
    onChange={(e) => this.setState({ assetDefectName: e.target.value },()=>{this.handleEmptyfields()})}
    label="Asset Defect Type"  
    renderValue={(value)=> {return value}}
    >
{this.renderAssetDefectTypeOptions()}
  </Select> */}


  <Autocomplete
                      id="free-solo-demo"
                      freeSolo={false}
                      options={this.state.combinedDefects.map((option)=>{ return option})}
                      value={this.state.assetDefectName}
                      inputValue={this.state.assetDefectName}
                    //  onOpen={this.getAssetDefectType}
                     getOptionLabel={(option) => option.iotassetdefecttypename}
                      // renderOption={this.renderAirlinesOptions()}
                      // filterOptions={filterOptions}
                     groupBy={(option) => option.category}
                     onChange={(event,value) => {this.setState({ assetDefectName:value.iotassetdefecttypename},()=>{this.handleEmptyfields()})}}
                      //  onInputChange={(event,values) => this.setState({ assetDefectName: values.iotassetdefecttypename},()=>{this.handleEmptyfields()})}
                      renderInput={(params) => (
                        <TextField {...params} label="Asset Defect Type" onChange={({ target }) => (target.value.iotassetdefecttypename)} variant="outlined" style={{ minWidth: "14.5rem" }} />
                        // <TextField {...params} label="Asset Defect Type"  variant="outlined" fullWidth />

                      )} 
                    >


                    </Autocomplete>
</FormControl>


<FormControl
                    variant="outlined"
                    required={true}

                  >
                       
<InputLabel htmlFor="component-outlined">
                      Asset defect findings
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetDefectFindings"
                      value={this.state.assetDefectFindings}
                      onChange={this.handleChange}
                      readOnly ={this.state.viewDefect}


                    />
                  </FormControl>


                  <FormControl
                    variant="outlined"
                    required={true}
                  >



                       
<InputLabel htmlFor="component-outlined">
                      Asset defect reported by
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetDefectReportedBy"
                      error={this.state.reportedByError}
                      value={this.state.assetDefectReportedBy}
                      onChange={this.handleReportedEmailChange}
                      readOnly ={this.state.viewDefect}


                    />
                  </FormControl>

           
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* <DateTimePicker
                      style={{ width: "15rem" }}
                      value={this.state.assetDefectLastReportedDate} //date from api
                      ampm={false}
                      disableToolbar
                      onChange={this.handleReportedDateChange}
                      label="Last defect reported date"
                      variant="inline"
                      showTodayButton
                      readOnly

                    /> */}
              
              <DateTimePicker 
        // renderInput={(props) => <TextField variant="outlined" {...props} />}
        label="Last defect reported date"
        value={this.state.assetDefectLastReportedDate}
        onChange={this.handleReportedDateChange}
        disableToolbar
        readOnly={true}
        ampm={false}
        style={{display:'flex' ,justifyContent:'space-around', minWidth:200}}
      />
             
             </MuiPickersUtilsProvider>

         
                  </Wrap>

                }

                  {this.state.viewDefect && ( <Wrap align="start" spacing="1-and-a-half-x" padding="half-x">

                  <FormControl
                    variant="outlined"
                    required={true}
                  >
                    <InputLabel htmlFor="component-outlined">
                      Asset defect repaired by
                    </InputLabel>
                    <OutlinedInput
                      type='email'
                      id="component-outlined"
                      name="assetDefectRepairedBy"
                      error={this.state.repairedByError}
                      value={this.state.assetDefectRepairedBy}
                      onChange={this.handleRepairedEmailChange}

                    />
                  </FormControl>

                  <FormControl variant="outlined" required={true} error={this.state.repairActionError}>
                    <InputLabel htmlFor="component-outlined">
                      Repair Action
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="repairAction"
                      required
                      value={this.state.repairAction}
                      onChange={this.handleChange}

                    />
                  </FormControl>
            
         

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    {/* <DateTimePicker
                   
                   variant="inline"
                   ampm={false}
                   disableToolbar
                   disablePast={true}
                      style={{ width: "15rem" }}
                      value={"Sun May 11 2021 00:00:00 GMT+0800 (Singapore Standard Time)"} //date from api
                      onChange={this.handleRepairedDateChange}
                      label="Last defect repaired date"
                      showTodayButton
                    /> */}

<DateTimePicker
        // renderInput={(props) => <TextField variant="outlined" {...props} />}
        label="Last defect repaired date"
        value={this.state.assetDefectLastRepairedDate}
        disableToolbar
        onChange={this.handleRepairedDateChange}
        style={{display:'flex' ,justifyContent:'space-around', minWidth:200}}
        // disablePast={true}
        variant={"inline"}
        ampm={false}
        minDate={this.state.assetDefectLastReportedDate}
        strictCompareDates={true}
        minDateMessage={"Please choose an appropriate date"}
         onError={this.handleDateError}
         PopoverProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin:{
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
      }
      />

</MuiPickersUtilsProvider>


        <FormControl style={{ minWidth: 200 }} variant="outlined">
  <InputLabel id="demo-simple-select-outlined-label">Asset Repair Status</InputLabel>
  <Select
    onOpen={this.getAssetRepairStatus}
    value={this.state.assetRepairStatus}
    onChange={(e) => this.setState({ assetRepairStatus: e.target.value })}
    label="Asset Repair Status"  
    renderValue={(value)=> {return value}}
    
    >
    {this.renderAssetRepairStatusOptions()}
  </Select>
</FormControl>


                 </Wrap>
                  )}
                </Stack>

             
                
              </AccordionDetails>
         
            </Accordion>
      
              <Stack  align ="end" padding="1-and-a-half-x" >

              <Button
       icon={<LoopIcon />}
       type="submit"
       onClick={this.resetForm}
       variant="warning"
       disabled={!this.state.hasScanned}
     >
      Reset
     </Button>
<br></br>
      
              { this.state.showUpdateDefectButton && <Button
       
       type="submit"
       onClick={this.updateAssetDefect}
       variant="success"
       disabled={!this.state.updateDefectButton}
       icon={<UpdateIcon/>}

     >
       Update Defect
     </Button>}

         
        { this.state.showSubmitDefectButton && <Button
       
           type="submit"
           onClick={this.submitAssetDefect}
           variant="success"
           disabled={!this.state.assetDefectButton}
           icon={<BuildIcon/>}
         >
           Submit Defect
         </Button>}
         </Stack>
          <br></br>

          <Dialog
            open={this.state.dialogOpen}
            onBackdropClick={() => {
              this.setState({ dialogOpen: false, qrError: false });
            }}
          >
            {/* {this.state.qrError && <Alert severity="error">Error Scan</Alert>} */}
            <DialogTitle id="simple-dialog-title">Scan QR Code</DialogTitle>

            <DialogContent>
            <div style={{ width: "16rem", height:"20rem",display:'center' }} >
        <QrReader
          delay={1000}
          onError={()=>{this.setState({qrError:true}); return 1;}}
          onScan={(result)=>{if (result !== null) this.parseJSONtoJSObject(result);return 1;}}
          // onScan={(result)=>{this.getAssetDefectDetails( "A-1230A");return 1;}}
          style={{  display:'center' }}
          facingMode={"environment"}
          resolution={1000}
        /> 

        <p>{this.state.result}</p>
      </div>
             
            </DialogContent>
          </Dialog>
            </div>
            </div>
    )}

    }


    export default withRouter((ReportDefect))