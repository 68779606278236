import React from "react";
import Map from '../components/Map/Map'
import { withStyles } from '@material-ui/core/styles';
import {getTrackers,getZoneData} from '../components/DownloadManager'
import {getTrackersTest} from '../components/DownloadManager'
import DataGridBase from '../components/DataGrid/DataGridBase.js'
import {Card, CardContent,Box} from '@material-ui/core';
import {Table,TableBody,TableCell,TableRow,TableContainer,Paper} from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import {cleanTrackerData} from '../components/DataManager.js'
import { Banner, Button, Flex, Container, Inline,  Stack, Wrap} from "@airbus/components-react";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = theme => ({
    root: {
      //display: 'static',
      flexGrow: 1,
    },
    content: {
      flexGrow: 0.5,
    //  padding: "0.5rem",
    },
    gridCard:
    {
      // minWidth: "50rem",
      height: 320,
      display: 'flex', 

    },
    infoSection:
    {
      //flexDirection: props => props.@material-ui/styles? "column" :"row",
      flexDirection:"row",
      display: 'flex', 
      justifyContent: 'space-between',
    },
    dataGrid:
    {
      //width: props => props.@material-ui/styles? '100%':'50%'
       width:'100rem',
    },
    infoPanel:
    {
      //width:props => props.isMobile? '100%':'50%',
      width:'10rem',
      height :'320'
    },
    tableRow:
    {
      height:10,
    },
    tableCell:
    {
      height:10,
      padding: "0.5rem 16px"
    },
    iconCard:
    {
      maxWidth:'2rem',
      height:'2rem',
      justifyContent:'justify-right'
    }

  });


  
const columns =[  //columns to populate react data grid with tracker data 
  {
    key:'serial',
    name:'ID',
    sortable: true,
    filterable: true,
    resizable: true,

  },
  {
    key:'sigfoxSerial',
    name:'Sigfox ID',
    sortable: true,
    filterable: true,
    resizable: true,


  },
  {
    key:'assetName',
    name:'Asset',
    sortable: true, 
    filterable: true,
    resizable: true,
    width: 405,

  },


]

  class MapView extends React.Component {

    state =
    {
        trackerData:[], //full list of trackers/assets
        filteredTrackerData:[],//subset of trackers/assets (due to use of filter/sort)
        selectedTracker:null, //currently selected tracker/asset
        geoZoneData:[],
        intervalTimer:null,
        lastUpdated:null,
        regionData:{}, //this is geozone data used for non-leaflet purposes
        testvar:[]
    }
    
    componentDidMount()
    {
      this.getZoneData();
      this.getTrackerData()
      var intervalTimer= setInterval( ()=>{this.getTrackerData();},300000)//300000ms = 5 minutes  //NEED TO MAKE THIS SELECTABLE/CONFIGURABLE EG. VIA DROPDOWN
      this.setState({intervalTimer})
      // window.dispatchEvent(new CustomEvent("resize"));
    }

    componentWillUnmount()
    {
      clearInterval(this.state.intervalTimer)
    }

    //call rest api to pull tracker data
    getTrackerData=()=>
    {
        //getTrackersTest(100,2, (res)=>  //DBGen test data . Requires DBGen local server/generator to be running
        getTrackers(500,2, (res)=>  //live data
        {
            this.cleanTrackerData(res)
            this.setState({lastUpdated:new Date().toLocaleString()})
            //console.log(res)
        })
    }

    //call rest api to pull zone data
    getZoneData=()=>
    {
      getZoneData(100,2, (res,newGeojson)=>
        {
          this.setState({testvar:newGeojson})
             this.cleanZoneData(res)
            //  console.log(res)
        })
    }

    //parses and cleans received data then stores updated values in state.
    cleanTrackerData=(data,rowCount=5000)=>
    {
        //function logic has been extracted to a function in DataManager component for reusability
        var trackerList=cleanTrackerData(data,rowCount);
        this.setState({trackerData:trackerList,filteredTrackerData:trackerList});
    }

    cleanZoneData=(data)=>
    {
    
      var regionData=this.state.regionData;
      //console.log(content);
      var newGeojson= //define new geojson store, initialize featurecollection(our used type) and an empty "features field" which we will push to after
      {
        "type":"FeatureCollection",
        "features":[],
      }
      var regexWhitspace=/\s/g; //search for ALL whitespace(tab and enter included)
      data.forEach(item => {

        var coordinates=[]
        coordinates=item.iotregioncoordinates.split(','); //split string by comma into individual array elements
        for(var i=0;i< coordinates.length;i++)
        {
          if(item.iotregiongeometrytype==null )break;
    
          coordinates[i]= coordinates[i].split(regexWhitspace);
          coordinates[i][0]= coordinates[i][0]*1; //apparently *1 is the fastest way to cast to number performance wise
          coordinates[i][1]= coordinates[i][1]*1;
    
          //Leaflet uses ISO defined Lat Lng, whereas GeoJSON uses Lng Lat. we need to swap these values.
          var temp = coordinates[i][0];
          coordinates[i][0]=coordinates[i][1];
          coordinates[i][1]=temp;
        }
        var coordinateArray=[]; 
        coordinateArray.push(coordinates);
        var features={
          "type":"Feature",
          "properties":{
            "ID":item.iotregionid,
            "Name":item.iotregionname,
            "Description":"empty for now" //to be added @backend.
          },
          "geometry":{
          "type":item.iotregiongeometrytype,
          "coordinates":coordinateArray
          }
        }
        newGeojson.features.push(features);
        regionData[item.iotregionid]=item
      });

      this.setState({geoZoneData:newGeojson,regionData:regionData}); 

    }

    setSelectedTracker=(selectedTracker)=>
    {
        this.setState({selectedTracker})
        //console.log(selectedTracker)
    }

    //to pass as callback to datagrid to store copy of filtered data
    setFilteredData=(data)=>
    {
      this.setState({filteredTrackerData:data})
    }

    //nullcheck then return region names
    getRegionData=(regionID)=>
    {
      if(regionID&& regionID.length>0) 
      {
        return this.state.regionData[regionID[0]].iotregionname
      }
      
      else return "None"
    }

     infoPanel=()=>
    {
      var rows=[]
      if(this.state.selectedTracker!==null) 
      {
        var tracker=this.state.selectedTracker;
        rows= //this is the data to be displayed in the INFO tab , add respectve KVP to increase data displayed
        [
          {"name":"ID","info":tracker.serial},
          {"name":"Asset","info":tracker.assetName},
          // {"name":"SigfoxID","info":tracker.sigfoxSerial},
          {"name":"Last Seen","info":tracker.time},
          {"name":"Zone","info":this.getRegionData(tracker.regionID)},//this.state.regionData[tracker.regionID[tracker.regionID.length]]},
          {"name":"Lat , Long","info":tracker.latLng.lat+" , "+tracker.latLng.lng},
          {"name":"State","info":tracker.iotsigfoxrawdata.state},
          {"name":"Source","info":tracker.iotsigfoxrawdata.source},
        ]
      }

      const {classes} = this.props;
      
      return(
        <>
         {/* <Box  width='30%' height ='320'> */}
          <Card >
            <CardContent>
            <p >
              <b style={{marginRight:'0rem '}}>Asset Info</b>
              {this.state.selectedTracker && <Paper elevation={3} className={classes.iconCard} >
              <DirectionsIcon style={{background: "white" ,color:'blue'}}  onClick={e=>this.getDirections(e)}/>
              </Paper>
              }
            </p>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name} className={classes.tableRow}>
                    {/* <TableCell component="th" scope="row"style={{backgroundColor:'red', color: 'white',}}> */}
                    <TableCell component="th" scope="row" className={classes.tableCell} style={{ backgroundColor:'LightBlue',color: 'black',height:20}}>
                      <b>{row.name}</b>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell} style={{ backgroundColor:'GhostWhite',color: 'black',}} >{row.info}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
            </CardContent>
          </Card>
        {/* </Box> */}
        </>
      )
    }

    getDirections=()=>
    {
      var url=' https://www.google.com/maps/search/?api=1&query='//28.6139,77.2090'
      url=url+this.state.selectedTracker.latLng.lat+','+this.state.selectedTracker.latLng.lng
      window.open(url);
    }
    
    //called when react data grid registers a click event on a cell
    rowClick=(rowId,row,column)=>
    {
      this.setState({selectedTracker:row})
      //console.log(row)
    }

     swapCoordinates=(GeoJSON)=> {
      for (var i = 0; i < GeoJSON.geometry.coordinates.length; i++) {
        /* if this is a polygon, length is the number of co-ordinates for this polygon. if 
        this is a multiPolygon, length is the number of geometries in this polygon */
    var paths=[]
            // iterate over this polygon's co-ordinates
            for (var j = 0; j < GeoJSON.geometry.coordinates[i].length; j++) {
                if (!paths) {
                    paths = [];
                }
                
                if (!paths[i]) {
                    paths[i] = [];
                }
                
                paths[i].push( GeoJSON.geometry.coordinates[i][j][1]+'\u0020'+ GeoJSON.geometry.coordinates[i][j][0]);

              }
             var paths2=[]
             paths2.push(paths.slice().join())
              // console.log(paths2)
            return paths
        }
        
      
    }
    render()
    
    {
      const {classes} = this.props;

 
        return(
        // <div className={classes.root} style={{}}>
         

          <Stack spacing="quarter-x">
            <Map trackerData={this.state.filteredTrackerData} selectedTracker={this.state.selectedTracker} setSelectedTracker={this.setSelectedTracker.bind(this)} 
            geoZoneData={this.state.geoZoneData}  testvar={this.state.testvar}/>
            <h6>LastUpdated : {this.state.lastUpdated},  {this.state.trackerData.length} Total Assets </h6>

      
              <Flex wrap={true} alignItems="start" justify="start" direction="row" style={{width:"100%"}}>
              <Container until="sm"  >
                <DataGridBase columns={columns} rows={this.state.trackerData} setFilteredData={this.setFilteredData.bind(this)} 
                 rowClickCallback={this.rowClick.bind(this)} rowHeight={30} height={320}  />

</Container>
  
        <Container until="sm" >
                {this.infoPanel()}
                </Container>
                </Flex>
            
            </Stack>
 
        )
    }

}

export default withStyles(styles,{index:1},{ name: 'MuiMapViewComponent' })(MapView);
