import React from "react";
import DataGrid , { SelectColumn, Column, RowsUpdateEvent, SortDirection,Filters } from 'react-data-grid'  //using canary v .16 due to bugs in later versions.
import 'react-data-grid/dist/react-data-grid.css';
 import './DataGrid.less'

import { withStyles } from '@material-ui/core/styles';
import {
    Hidden,

  } from "@airbus/components-react";
import { display } from "@material-ui/system";


const styles = theme => ({
    root: {
        height: '100%',
        flexGrow:1,
    },
    rdgfilter:
    {
 
        height: "2em",
        fontSize: "1em",
        flexGrow:0.8,
        display: 'flex',
        width:'90%'
    },
    rdgfilterContainer:
    {
        display: "flex",
        height: "inherit",
        alignItems: "center",
        flexGrow:0.8,

 


    },
    // allFeatures:
    // {
    //     display: "flex",
    //     flexDirection: "column",
    //     height: "100%",
    // }
    
  });

class DataGridBase extends React.Component
{
    state=
    {
        initialRows:this.props.rows,
        preSortedRows:null,
        rows: this.props.rows, //rows post process (eg filter,sort)
        columns:this.props.columns,
        columns2:this.props.columns2,
        selectedRows:new Set(), //the sorted rows . We display this
        sortColumn:null,
        sortDirection:['serial', 'DESC'],
        filters:{},
        enableFilters:true,
    }

    componentDidMount()
    {
        this.addFilters();
        if(this.props.filters)
        {
            this.addFilters();
        }

    }
    componentDidUpdate()
    {
        if(this.props.rows!==this.state.initialRows) this.setState({initialRows:this.props.rows,rows:this.props.rows})
    }

    setRows =() =>
    {
        console.log("test")
    }

    //handles sorting of columns by asc,desc, or default
    handleSort=(sortColumn, sortDirection)=>
    {
        var sortedRows=null;
        if(sortDirection === 'NONE') 
        {
            this.setState({rows:this.state.preSortedRows,sortColumn, sortDirection},()=>
            {
                this.setState({preSortedRows:null})
            })
            return ; 
        }//dont even compare and return if we arent sorting

        if(this.state.preSortedRows===null) 
        {
            this.setState({preSortedRows:this.state.rows}) //we store this so we have a default state AFTER filter is applied
            sortedRows=this.state.rows.slice();
        }
        else sortedRows=this.state.preSortedRows.slice()

        sortedRows.sort((a, b) => {
            if(!a[sortColumn]) return -1 //if doesnt exist/null. 
            if(!b[sortColumn]) return +1 //if doesnt exist/null. 
            return a[sortColumn].localeCompare(b[sortColumn], undefined, { numeric: true, sensitivity: 'base' })
        });  
        ///localeCompare allows for alphanumeric comparison in a "human" way  eg 1,5,10 vs computer 1,10,5
        ///we can access object array values dynamically by using array[keyName] 
         
        if(sortDirection === 'ASC' ) sortedRows=sortedRows.reverse() ;
        this.setState({rows:sortedRows,sortColumn, sortDirection})
    }

    //we use this so set filters  //MODIFY HERE to make dynamic filter checks
    setFilter=(filters)=>
    {
        if(filters!=={})
        {
            var combinedFilters={...this.state.filters,...filters} //merge 2 objects
            // this.setState({filters:combinedFilters})
            var rows=this.filterArray(this.state.initialRows,combinedFilters)
            this.setState({filters:combinedFilters,rows:rows,preSortedRows:null,sortDirection:['serial', 'DESC']}) //we clear sorting if filter is interacted with

            if(this.props.setFilteredData) this.props.setFilteredData(rows)
            
        }
    }

    //filter rows/data based on filters selected/keyed in
    //compare array of data objects to filter objects. If value is undefined, empty or does not include a partial match, DONT return
    ///array= array of objects  filters= objects where KVP are filter options and KEYS matching array
     filterArray=(array, filters) =>{

        var filteredArray= array.filter(function(item) {
            for (var key in filters) {
              if (item[key] === undefined || item[key] === "" ||!(item[key].toLowerCase().includes(filters[key].toLowerCase())) )
                return false;
            }
            return true;
          });
          return(filteredArray)
      }

    //we check if columns have filterable tag, and append the appropriate header renderer
    addFilters=()=>
    {
        const {classes} = this.props; //to access withStyles prop
        var columns=this.state.columns.slice()

        columns.map((column,index)=>
        {
            if(column.filterable===true) //if we pass in the filterable option , set the renderer
            {
                
                column.filterRenderer= p=>
                (
                    <div className={classes.rdgfilterContainer}>
                                        
                                        {/* <div > */}

                        <input
                        // className={classes.rdgfilter}
                        className={classes.rdgfilter}
                        // style={(column.width!==null)?{maxWidth:column.width-30}:{}}
                          //style={(column.name==="ID"||column.name==="Sigfox ID")?{maxWidth:"9em"}:{}}
                        value={p.value}
                        onChange={e => p.onChange(e.target.value)}
                        //onChange={e => console.log(p.column.name)}
                        />
                    </div>
                )
            }
        })
    }

    rowClickHandler=(rowId,row,column)=>
    {
        //if callback exists, call it and pass data through
        if(this.props.rowClickCallback) this.props.rowClickCallback(rowId,row,column)
    }
    
    render()
    {
        return(
        <div 
        // className="allFeatures"
        >
        
            <DataGrid 
            // className="big-grid"
            className="rdg-light"
            columns={this.state.columns} 
            rows={this.state.rows} 
            defaultColumnOptions={{
                sortable: true,
                resizable: true
            }}
            sortColumn={this.state.sortColumn} //the column to sort by
            sortDirection={this.state.sortDirection} //the direction to sort in
            onSort={this.handleSort}
            onRowsChange={this.setRows}
            // rowClass={'highlight'}
            enableFilters={this.state.enableFilters}
            onFiltersChange={this.setFilter}
            rowHeight={this.props.rowHeight} //height of display row
            height={this.props.height} //total grid height
            onRowClick={this.rowClickHandler}
            minColumnWidth={1000}
            headerFiltersWidth={100}
            style={{width:'100%'}}

  
            />


      
      
       
        </div>
        )
    }
    
}
export default withStyles(styles) (DataGridBase);