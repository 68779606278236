import React, { Component } from 'react';
import {Typography} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    space:
    {
  
      align:"center",
      alignItems:"flex-end",
      color: 'white',
      marginTop:"36rem",
      bottom: "0",
      justifyContent: 'flex-end',
      // marginBottom: 36
    },
  
    });
class Clockv1 extends Component
{
    state=
    {
        time:new Date().toLocaleString()
    }


    componentDidMount()
    {           
        this.intervalID = setInterval(
            () => this.tick(),
            1000
          );
    }
    componentWillUnmount()
    {
        clearInterval(this.intervalID);
    }
    tick() {
        this.setState({
          time: new Date().toLocaleString()
        });
      }


    render()
    {
        const {classes} = this.props;
        return(
            //<Typography  variant={"caption"} align={"center"} > {this.state.time} </Typography >
            <Typography  variant={"caption"} align={"center"} className={classes.space }> {this.state.time} </Typography >
        )
    }
}

export default withStyles(styles)(Clockv1); 