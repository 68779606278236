import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import  {createBrowserHistory} from "history"
import { HashRouter, Route, Switch, Redirect,Router } from "react-router-dom";
import User from './view/User.js'
import Login from './components/Authentication/LoginPage.js'
import PersonalData from './view/Disclaimer/PersonalData.js'
import Auth from './components/Authentication/Authentication.js'
// import {  ThemeProvider } from 'horizon-components-react';
import { StylesProvider,createGenerateClassName  } from '@material-ui/core/styles';
import QRScan from './view/QRScan'
import ReportDefect from './view/ReportDefect';


// import QRScan from './view/QRScan.js'

const hist= createBrowserHistory(); //this is so we can use "back" for client routing

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

ReactDOM.render(
  <Router history={hist}>
     {/* <StylesProvider injectFirst generateClassName={generateClassName} > */}
    {/* <ThemeProvider theme={'airbus'}> */}
    <Switch>
      <Route  path="/sso"  component={() => { 
      window.location.href = process.env.REACT_APP_SSO+'/login?success_callbackurl='+window.location.origin+'/auth&failure_callbackurl='+window.location.origin+'/login&applicationname=IoT';
      return null;
      }} /> 
      <Route path="/login" component={Login}/>
      <Route path="/personaldata" component={PersonalData}/>
      <Route path="/user" component={User}/>
      <Route path="/admin" component={User}/>
      <Route path="/auth" component={Auth}/>
     <Route path="/qrscan" component={QRScan}/>
     <Route path ="/reportdefect" component={ReportDefect} /> 
      {/* <Route path="/user" component={User} />
      <Route path="/admin" component={Admin} /> */}
      <Redirect from="/" to= "/login"/>
    </Switch>
    {/* </ThemeProvider> */}
    {/* </StylesProvider> */}
  </Router>,
  
  document.getElementById('root')
);


