import moment from 'moment';

//parses and cleans received data then stores updated values in state.
export const cleanTrackerData=(data,rowCount)=>
{
    var trackerList=[];
    for(var i=0;i<data.length;i++)
    {
      var latLng= {}; //latlng object to be embedded
      var tracker={}; //single marker object, containts latLng
      if (data[i].iotsigfoxprocesseddata.latitude!=null)  //loop through data values to find first non-null latlng
      {  
        tracker.serial=data[i].iotassetserialno//asset serial 
        
        if(trackerList.findIndex(i=>i.serial===tracker.serial)<0) ///check for duplicate in list
        {
          latLng.lat=data[i].iotsigfoxprocesseddata.latitude; //store lat
          latLng.lng=data[i].iotsigfoxprocesseddata.longitude; //store lng
          tracker.latLng=latLng; //store latlng into a marker parent
          tracker.sigfoxSerial=data[i].iotsigfoxrawdata.data.serial; //sigfox serial
          tracker.iotsigfoxrawdata=data[i].iotsigfoxrawdata.data; 
          tracker.iotsigfoxprocesseddata=data[i].iotsigfoxrawdata;
          tracker.assetName=data[i].iotassetname;// asset name
          tracker.regionID=data[i].iotregionid;
          //tracker.regionName=this.state.regionData[tracker.regionID[0]].iotregionname
          //tracker.zone=
          //tracker.time=data[i].iotsigfoxtime; //time
          let UploadDate = moment.utc(data[i].iotsigfoxtime).local();
          tracker.time=UploadDate.format('MMMM Do YYYY, HH:mm:ss a');
          trackerList.push(tracker); 
        }
        //else{console.log("duplicate:"+ marker.title);} 
      }
      if(i===rowCount){break} //prevent infinite or long loops
    }
    return trackerList;
}