import React, { Component } from 'react';
import { BrowserRouter as Route, Switch ,Link, Redirect,  useRouteMatch, withRouter } from 'react-router-dom';
// import Header from '../components/header/testheader'
import Sidebar from "../components/sidebar/sidebar";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MapView from "./MapView.js"
// import newPage from "./newPage"
import QRScan from './QRScan.js'
import {Flex} from'@airbus/components-react'
import ReportDefect from './ReportDefect.js';
import Changelog from "./Changelog/Changelog.js";
import Header from '../components/header/OldHeader'

const drawerWidth = "9rem";
const styles = theme => ({
    root: {
    //   display: 'static',
    flexGrow: 1,
    },
    content: {
      flexGrow: 1,
     padding: "0.5rem",
    },

    shiftTextLeft: {
      marginLeft: "2.5rem"
    },
    shiftTextRight: {
      marginLeft: drawerWidth,
    },
    backdrop: {
        //zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  });


class User extends Component
{
    state=
    {
        drawerIsOpen:true,
        isAuthenticated:null,
        role:null,
        user: //userdata from log in/jwt
        {
            username:null,//"adminA@hotmail.com",//null,
            userid:null,//22,
            company:null,//"Company A",//null
            companyid:null,//"3"
            logintokenexpirydatetime:null,
            authenticateddatetime:null
        },
        selectedTab:-1,
        isMobile:null,
    }

    componentDidMount()
    {
        this.isAuthenticated();
        window.addEventListener('resize', this.isMobileMode);
        this.isMobileMode()       
    }

    //clear user data when unmount
    componentWillUnmount()
    {
        this.setState({
            isAuthenticated:null,
            role:null,
            user: //userdata from log in/jwt
            {
                username:this.null,//"adminA@hotmail.com",//null,
                userid:this.null,//22,
                company:null,//"Company A",//null
                companyid:null//"3"
            },
            fileList:[], //list of  files available for download.WE KEEP THIS AT A VIEW LEVEL SO ITS ACCESSIBLE FROM CHILD COMPONENTS
            //myUploadedFileList:[],
            contactList:[],
        })
        window.removeEventListener('resize', this.isMobileMode)
    }

    isMobileMode=()=>
    {
        if (window.innerWidth <= 960) this.setState({isMobile:true})
        else this.setState({isMobile:false})
    }
    //check again if user is authenticated before allowing access to features
    isAuthenticated()
    {
        if((this.props==null)||(this.props.location.state==null)||(this.props.location.state.isAuthenticated==null)) this.props.history.push('/auth')  //auth checks, else reroute to auth
  
        else {
            this.setState({isAuthenticated:this.props.location.state.isAuthenticated,role:this.props.location.state.role[0].applicationrolename.toLowerCase()})
            var user =
            {
                username:this.props.location.state.userData.username,//"adminA@hotmail.com",//null,
                userid:this.props.location.state.userData.userid,//22,
                company:this.props.location.state.userData.organizationname,//"Company A",//null
                companyid:this.props.location.state.userData.organizationid,//"3"
                logintokenexpirydatetime:this.props.location.state.userData.logintokenexpirydatetime,
                authenticateddatetime:this.props.location.state.userData.authenticateddatetime,
                role:this.props.location.state.userData.userapplicationrole[0].applicationrolename,
                loginToken:this.props.location.state.logintoken
            }
            //console.log(this.props.location.state.userData)
            this.setState({user:user})
            //this.setState({selectedTab:0}) //HARDCODED FOR NOW UNTIL FURTHER ROUTES
            setTimeout(()=>{ this.setRoute(0) }, 100);
            //this.props.history.push('/MapView')
        }
    }


     //#region tab Management
     drawerState=(isOpen)=>{this.setState({drawerIsOpen:isOpen})}

     //we standardize route setting to this function to prevent overlapping calls
     setRoute=(tab)=>{
        const { path } = this.props.match;
        if (this.state.selectedTab==tab) return;  //if already on the correct tab, skip
        //  if(index==0) {this.props.history.push( "/"+this.state.role+"/uploadFile");}
        if(tab==0) {this.props.history.push( `${path}/MapView`);}
        if(tab==1) {this.props.history.push( `${path}/QRScan`);}
        if(tab==2) {this.props.history.push( `${path}/ReportDefect`);}
        if(tab==-1) {this.props.history.push('/auth');}
        this.setState({selectedTab:tab})

     }

     //#endregion

    render()
    {
        const { classes } = this.props;
        const { path, url } = this.props.match; //path uses parent provided url as base. url uses current value
        
        return(
      
           
             <div id="parent" className={classes.root}> 
               
     
            {/* <div> */}
               
               {/* {Header and sidebar section. These values are fixed and dont change} */}
                    {/* <TestHeader setTab={this.setRoute.bind(this)} selectedTab={this.state.selectedTab} username={this.state.user.username} role={this.state.role}/> */}
<Header setTab={this.setRoute.bind(this)} selectedTab={this.state.selectedTab} username={this.state.user.username} role={this.state.role}></Header>

                    <Flex style={{overflow:"hidden", height:"100rem"}}>
                    <Sidebar role={this.state.role} drawerState={this.drawerState.bind(this)} setTab={this.setRoute.bind(this)} selectedTab={this.state.selectedTab} />
                    
                 
                 

                <main className={classes.content}>
                    {/* <div id="dynamicUI" className={this.state.drawerIsOpen ? classes.shiftTextRight : classes.shiftTextLeft}>  */}
                    {/* Route based section, aka child content. Dynamic/changes */}
     
                    <Switch>
                         <Route exact path={path}>
                         </Route>
                        <Route path={`${path}/MapView`}>
                            <MapView setTab={this.setRoute.bind(this)}  isMobile={this.state.isMobile} username={this.state.user.username}/>
                        </Route>
                        {/* <Route >
                            <MapView setTab={this.setRoute.bind(this)}  isMobile={this.state.isMobile}/>
                        </Route> */}
                        <Route path={`${path}/QRScan`}>
                            <QRScan setTab={this.setRoute.bind(this)} role={this.state.role} username = {this.state.user.username} isMobile={this.state.isMobile}/>
                        </Route>
                        <Route path={`${path}/ReportDefect`}>
                            <ReportDefect setTab={this.setRoute.bind(this)}  username = {this.state.user.username} isMobile={this.state.isMobile}/>
                        </Route>
                        {/* <Route path={`${path}`}>
                            <Redirect to= {`${path}/MapView`}/>
                        </Route> */}
                        {/* <Map /> */}
                        <Route path={`${path}/changelog`}>
                        <Changelog role={this.state.role}/>
                        </Route>
                    </Switch>
               
                    {/* </div> */}
                 </main>
            {/* </div> */}

            </Flex> 
            </div>
          
        )
    }

}

export default withStyles(styles,{index:1},{ name: 'MuiUserComponent' })(User);