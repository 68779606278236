import React from "react";
import { getTrackers, getZoneData, getAssets } from "../components/DownloadManager";
import { cleanTrackerData} from "../components/DataManager";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from '@material-ui/icons/Search';
import {
  Accordion,
  Backdrop,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
// var passwordValidator = require('password-validator'); // for regex library
// import {matchSorter} from 'match-sorter'
import { Banner, Button, Container, Stack, Wrap } from "@airbus/components-react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from 'date-fns'
import CropFreeIcon from "@material-ui/icons/CropFree";
import LoopIcon from '@material-ui/icons/Loop';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import QrReader from 'modern-react-qr-reader'
import {withRouter} from 'react-router-dom';


axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_BASE_API_KEY;

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  infoSection: {
    flexDirection: (props) => (props.isMobile ? "column" : "row"),
    display: "flex",
    justifyContent: "space-between",
  },

  // qrPanel:
  // {
  //   width:props => props.isMobile? '100%':'50%',
  //   height :'300'
  // },

  // qrPanel2:{
  //   width:props => props.isMobile? '100%':'50%',
  //   height :'300'
  // },
});

const columns = [
  //columns to populate react data grid with tracker data
  {
    key: "serial",
    name: "ID",
    sortable: true,
    filterable: true,
  },
  {
    key: "assetName",
    name: "Asset",
    sortable: true,
    filterable: true,
  },
  {
    key: "sigfoxSerial",
    name: "Sigfox ID",
    sortable: true,
    filterable: true,
  },
];

class QRScan extends React.Component {
  state = {
    trackerData: [],
    data:null,
    selectedTracker: null,
    ownerRegion: null,
    initialRows: [],
    dialogOpen: false,
    preSortedRows: null,
    rows: [], //rows post process (eg filter,sort)
    sortColumn: null,
    sortDirection: ["serial", "DESC"],
    assetID: "",
    assetName: "",
    assetLastCheckedOutDate:null,
    assetSerialNo: "",
    assetStatus: "",
    assetStatusColor: "default",
    assetType: "",
    assetCategory: "",
    assetUsageLogID: "",
    assetRepairStatus:"",
    assetRepairStatusColor: "default",
    assetUnderRepair: null,
    isLoading:false,
    locationID: "",
    locationName: "",
    locationStatus: "",
    locationStatusColor: "default",
    qrError: null,
    repairID: "",
    repairName: "",
    repairStatus: "",
    zone: "",
    hasCheckedOut: null,
    assets:null,
    isDoneCheckIn:null,
    isDoneCheckOut:null,
    enableReset:false
  };

  componentDidMount() {
    this.getTrackerData();
    this.getAssetData();
    this.resetForm()
    //this.getZoneData()
  }

  //call rest api to pull tracker data
  getTrackerData = () => {
    // getTrackersTest(100,2, (res)=>
    getTrackers(100, 2, (res) => {
      //console.log(res)
      cleanTrackerData(res);
    });
  };

  //call rest api to pull zone data
  getZoneData = () => {
    getZoneData(100, 2, (res) => {
      console.log(res);
    });
  };

  getAssetData = () => {
    getAssets(100, 2, (res) => {
    this.setState({assets:res})
    });
  };

  openDialog = () => {
    this.resetForm();
    this.setState({ dialogOpen: true,qrError:false });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  parseJSONtoJSObject=(qrString)=>{

    //  var regexToMatch = /(?<=S\/N:).*?(?=\s)/; 
     var regexToMatch = /.*S\/N:+(.*)\s/; 
     var myArray = qrString.match(regexToMatch)
    //  console.log(myArray[1])
    //  console.log(myArray)
     if(myArray && myArray[1]){
  
  //  console.log(result)
      // this.getAssetDefectDetails(result.iotassetserialno)
   this.getAssetDetails(myArray[1])
    // this.getAssetDetails("A-1253A")

    return 1;
  }
    else{
      this.setState({qrError:true,dialogOpen:false,enableReset:true})
      return 1;
     }

  }

// retrieve asset data based on QR serial no.
getAssetDetails=(iotassetserialno)=>
{
  // console.log(iotassetserialno )
   var asset =this.state.assets.find(x => x.iotassetserialno === iotassetserialno.toUpperCase()) 

  if (asset)
  {
    axios.get(`${process.env.REACT_APP_BASE_URL}/asset`, {
      headers: {
        "x-api-key": process.env.REACT_APP_BASE_API_KEY,
      },
      params: {
        iotassetid: asset.iotassetid,
        join:1
      },
    })
    .then((res) => {
      this.setState({ data: res.data, dialogOpen: false,enableReset:true,qrError:false }, () => {this.toPopulateAsset(this.state.data)});
  
      return 1;
    })
    .catch((err) => {
      console.log(err.response);
      this.setState({enableReset:true})
      return 1;
    });
  }
  else
  {
    console.log("asset doesn't exist")
    this.setState({dialogOpen: false, qrError:true,enableReset:true })
   return -1;
   
  }

};

  // populate asset fields after scan QR
  toPopulateAsset = (mesg) => {
    if (mesg == null || undefined) {
      return 1;
    } else {
      
      var assetID = (mesg[0].iotassetid);
      var assetName = (mesg[0].iotassetname);
      // var sigfoxID =(mesg[0].iotsigfoxid)
      // var assetStatusColor = "primary"; // check available for use / maintainance

    function checkStatusColor(mesg){
      return (mesg[0].iotassetusagelogcheckedoutdatetime != null  ?  "secondary":"primary")
    }

    function checkStatus(mesg){
      return (mesg[0].iotassetusagelogcheckedoutdatetime != null  ?  "Not Avaliable":"Available")
    }

    function checkRepairStatusColor(mesg){
      switch(mesg[0].iotassetrepairstatusid){
      case null:
      return 'default' 
    
      case 1:
      return 'secondary'

      case 2:
      return 'primary'

      case 3: 
      return 'secondary' 

      default: 
      return 'default'
      }
    }
  

    var assetStatusColor = checkStatusColor(mesg)
    var assetStatus = checkStatus(mesg)
    var assetRepairStatus = mesg[0].iotassetrepairstatusname
    var assetRepairStatusColor = checkRepairStatusColor(mesg)
    var assetSerialNo = mesg[0].iotassetserialno
    var assetLastCheckedOutUserID= mesg[0].iotassetusagelogcheckedoutuserid
    var assetLastCheckedOutDate = (mesg[0].iotassetusagelogcheckedoutdatetime)

    var assetUnderRepair = null
    mesg[0].iotassetrepairstatusid !=null && mesg[0].iotassetrepairstatusid !=2 ? assetUnderRepair= true : assetUnderRepair =false
      if ((assetLastCheckedOutUserID == null || assetLastCheckedOutDate == null) && assetUnderRepair!= true ) {
      var hasCheckedOut= false   // user can check out
      this.setState({
        assetID: assetID,
        assetName: assetName,
        assetLastCheckedOutDate:assetLastCheckedOutDate,
        assetStatus: assetStatus,
        assetStatusColor: assetStatusColor,
        assetSerialNo:assetSerialNo,
        hasCheckedOut: hasCheckedOut,
        assetUnderRepair:assetUnderRepair, 
        assetRepairStatus:assetRepairStatus,
        assetRepairStatusColor: assetRepairStatusColor,
      });
      return 1;
    }
    else if ((assetLastCheckedOutUserID == null || assetLastCheckedOutDate == null) && assetUnderRepair== true ) {
      var hasCheckedOut= false   // user can check out
      
      this.setState({
        assetID: assetID,
        assetName: assetName,
        assetLastCheckedOutDate:assetLastCheckedOutDate,
        assetStatus: assetStatus,
        assetStatusColor: assetStatusColor,
        assetSerialNo:assetSerialNo,
        hasCheckedOut: hasCheckedOut,
        assetUnderRepair:assetUnderRepair, 
        assetRepairStatus:assetRepairStatus,
        assetRepairStatusColor: assetRepairStatusColor,
      });
      return 1;
    

    }
    else {  // user can check in
      var hasCheckedOut= true
      var assetUsageLogID = mesg[0].iotassetusagelogid
      var assetRepairStatus = mesg[0].iotassetrepairstatusname
      var assetRepairStatusColor = checkRepairStatusColor(mesg)
this.setState({ 
  assetID: assetID,
  assetName: assetName,
  assetLastCheckedOutDate:assetLastCheckedOutDate,
  assetStatus: assetStatus,
  assetStatusColor: assetStatusColor,
  assetSerialNo:assetSerialNo,
  assetRepairStatus:assetRepairStatus,
  assetRepairStatusColor: assetRepairStatusColor,
  hasCheckedOut:hasCheckedOut,
  assetUsageLogID: assetUsageLogID,
  assetUnderRepair:assetUnderRepair,
})
  return 1;

    }
    }
  };


// resets the web page
  resetForm = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    this.setState({
      assetID: "",
      assetName: "",
      assetStatus: "",
      assetStatusColor: "default",
      assetLastCheckedOutDate:null,
      assetRepairStatus:"",
      assetRepairStatusColor:"default",
      assetSerialNo:"",
      assetUsageLogID: "",
      data:null,
      locationID: "",
      locationName: "",
      locationStatus: "",
      locationStatusColor: "default",
      zone: "",
      hasCheckedOut: null,
      qrError: null,
      isLoading:false,
      assetUnderRepair: null,
      enableReset:false,

    });
  };

  updateValue = (event, newValue) => {
    this.setState({ zone: newValue });
  };

  //handle api call for check in
  handleCheckIn=()=>{
    // var  aaa= format(new Date(), 'yyyy-MM-dd kk:mm:ss')
    // console.log(aaa)
    window.scrollTo({top: 0, behavior: 'smooth'})
    this.setState({isLoading:true})
    axios.patch(`${process.env.REACT_APP_BASE_URL}/asset_usage_log`, [
	
      {
        iotassetusagelogcheckedinuserid:this.props.username,
        iotassetusagelogcheckedindatetime:format(new Date(), 'yyyy-MM-dd kk:mm:ss'),
        iotassetusagelogid:this.state.assetUsageLogID,
        iotassetid:this.state.assetID
      
       
        }
    ]
    )
    .then((res) => {
      
      this.setState({ data: res.data, dialogOpen: false,hasCheckedOut:false,isDoneCheckIn:true });
      setTimeout(()=>{this.setState({isDoneCheckIn:false,isLoading:false},()=>{this.resetForm()})},3000)
      return 1;
    })
    .catch((err) => {
      console.log(err.response);
      return 1;
    });
  }

  //handle api call for check out
  handleCheckOut=()=>{
    // var  aaa= format(new Date(), 'yyyy-MM-dd kk:mm:ss')
    // console.log(String(aaa))
    window.scrollTo({top: 0, behavior: 'smooth'})
    this.setState({isLoading:true})
    axios.post(`${process.env.REACT_APP_BASE_URL}/asset_usage_log`, [
	
      {
        iotassetusagelogcheckedoutuserid:this.props.username,
        iotassetusagelogcheckedoutdatetime:String(format(new Date(), 'yyyy-MM-dd kk:mm:ss')),
        iotassetid:this.state.assetID,
       
        }
    ]
    )
    .then((res) => {
      this.setState({ data: res.data, dialogOpen: false, hasCheckedOut:true,isDoneCheckOut:true });
      setTimeout(()=>{this.setState({isDoneCheckOut:false, isLoading:false},()=>{this.resetForm()})},3000)
      return 1;
    })
    .catch((err) => {
      console.log(err.response);
      return 1;
    });
  }

  handleChange = (event) => {
    this.resetForm()
    this.setState({ [event.target.name]: event.target.value})
  }

  render() {
    var preSortRows = this.state.rows.map((row) => row.iotregionname);
    var postSortRows = preSortRows.sort((a, b) =>
      a.localeCompare(b, "en", { numeric: true, sensitivity: "base" })
    );
    const { classes } = this.props;
    return (
      <div>
           
 { this.state.qrError && <Banner variant="error">Invalid Asset</Banner>}
 {/* { this.state.searchError && <Banner variant="error">Invalid Asset</Banner>} */}
 { this.state.isDoneCheckIn && <Banner variant="success">Asset {this.state.assetSerialNo} has been successfully checked in </Banner>}
 { this.state.isDoneCheckOut && <Banner variant="success">Asset {this.state.assetSerialNo} has been successfully checked out </Banner>}

        <h2>QR Scanning</h2>
        <div style={{marginTop:'1rem'}}>

          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                variant={"h5"}
                color="primary"
              >
                QR Scan (Asset Check In/Out)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            {this.state.isLoading && (<Backdrop style={{zIndex:1}} open={this.state.isLoading} transitionDuration={50}>
              <CircularProgress
                style={{ marginTop: "1.5rem", marginLeft: "2rem" }}
              />
              </Backdrop>
            )}
              <Stack spacing="1-x">
                <Wrap as="ul" spacing="1-x">
                  <FormControl
                    variant="outlined"
                    // required={true}
                    error={this.state.assetIDError}
                  >
                    <InputLabel htmlFor="component-outlined">
                      Asset ID
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetID"
                      value={this.state.assetID}
                    />
                  </FormControl>

                  <FormControl variant="outlined" >
                    <InputLabel htmlFor="component-outlined">
                      Asset Name
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetName"
                      value={this.state.assetName}
                      fullWidth
                      multiline
                      rowsMax="3"
                    />
                  </FormControl>

                  <FormControl variant="outlined" required={true}>
                    <InputLabel htmlFor="component-outlined">
                      Asset Serial No.
                    </InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      name="assetSerialNo"
                      value={this.state.assetSerialNo}
                      onChange={this.handleChange}

                    />
                  </FormControl>

                  {/* <FormControl variant="outlined" required={true} >
              <InputLabel htmlFor="component-outlined">Asset Type</InputLabel>
              <OutlinedInput id="component-outlined" name="assetType" value={this.state.assetType} />
            </FormControl>

            <FormControl variant="outlined" required={true} >
              <InputLabel htmlFor="component-outlined">Asset Category</InputLabel>
              <OutlinedInput id="component-outlined" name="assetCategory" value={this.state.assetCategory} />
            </FormControl> */}

                  <Button
                    variant="primary"
                    style={{ marginTop: "1rem" }}
                    onClick={this.openDialog}
                    icon={<CropFreeIcon />}
                    disabled={this.state.assetSerialNo }
                  >
                    Scan QR
                  </Button>
                  <Button
                    variant="primary"
                    style={{ marginTop: "1rem" }}
                    onClick={()=>{   this.getAssetData();var assetSN =this.state.assetSerialNo
                      // console.log(assetID)
                      if(assetSN){this.getAssetDetails(assetSN)} else(this.setState({qrError:true,enableReset:true})); return 1; }}
                    icon={<SearchIcon />}
                    disabled={!this.state.assetSerialNo  }
                  >
                    Manual Search
                  </Button>

                  <div style={{ paddingTop: "1rem" }}>
                    <b>Asset Status: </b>

                    <Chip
                      icon={<FaceIcon />}
                      label={this.state.assetStatus}
                      
                      color={this.state.assetStatusColor}
                      deleteIcon={<DoneIcon />}
                    />
                  </div>

                  <br></br>
                 { this.state.hasCheckedOut && 
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      disableToolbar
                      readOnly
                      style={{ width: "15rem" }}
                      value={this.state.assetLastCheckedOutDate} //date from api
                      disablePast
                      // onChange={handleDateChange}
                      label="Last checked out date"
                      showTodayButton
                    />
                </MuiPickersUtilsProvider>
                  }
                </Wrap>
                <br></br>

                {this.state.assetUnderRepair &&(< Wrap align="start" spacing="1-and-a-half-x" padding="half-x">
             
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      disableToolbar
                      readOnly
                      style={{ width: "15rem" }}
                      value={this.state.assetDefectLastReportedDate} //date from api
                      disablePast
                      // onChange={handleDateChange}
                      label="Last asset defect reported date"
                      showTodayButton
                    />
                  </MuiPickersUtilsProvider> */}
                  <div style={{ paddingTop: "1rem" }}>
                    <b>Asset Repair Status: {""}</b>

                    <Chip
                      icon={<FaceIcon />}
                      label={this.state.assetRepairStatus}
                      color={this.state.assetRepairStatusColor}
                      deleteIcon={<DoneIcon />}
                    />
                  </div>
                </Wrap>)}



                {(this.state.assetUnderRepair != true && this.state.assetUnderRepair !=null ) &&( <Wrap as="ul" spacing="1-and-a-half-x" padding="1-x">

                   <Button
                    type="submit"
                    onClick={this.handleCheckIn}
                    variant="success"
                    disabled={!this.state.hasCheckedOut}  // true - disabled
                    // disabled={true}  // true - disabled

                    icon={<PublishIcon/>}

                  >
                    Check in
                  </Button>
                
                  <Button
                    type="submit"
                    onClick={this.handleCheckOut}
                    variant="success"
                    disabled={this.state.hasCheckedOut} // true - disabled
                    // disabled={true}  // true - disabled
                    icon={<GetAppIcon/>}

                  >
                    Check out
                  </Button>
  
                </Wrap>)}
              </Stack>
            </AccordionDetails>
          </Accordion>





          {/* </Box> */}
          <Stack  align ="end"   style={{marginRight:"2.4rem"}} >
         
            <Button
          
              type="submit"
              onClick={this.resetForm}
              variant="warning"
              disabled={!this.state.enableReset}
              icon={<LoopIcon />}

            >
              Reset
            </Button>
            </Stack>
        
            
          <Dialog 

            open={this.state.dialogOpen}
            onBackdropClick={() => {
              this.setState({ dialogOpen: false, qrError: false });
            }}
          >
            {/* {this.state.qrError && <Alert severity="error">Error Scan</Alert>} */}
            <DialogTitle id="simple-dialog-title">Scan QR Code</DialogTitle>

            <DialogContent 
  >          

            <div style={{ width: "16rem", height:"20rem",display:'center' }} >
        <QrReader
          delay={1000}
          onError={()=>{this.setState({qrError:true}); return 1;}}
          onScan={(result)=>{if (result != null) this.parseJSONtoJSObject(result); return 1;}}
          // onScan={(result)=>{this.parseJSONtoJSObject(result)}}
          resolution={1000}
          style={{  display:'center' }}
          // facingMode={"environment"}
                  />
        <p>{this.state.result}</p>

      </div>
             
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
}


//with router
export default withRouter(QRScan);
