import React from 'react';
import {useState,useEffect}from "react";
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MapIcon from '@material-ui/icons/Map';
import QRIcon from '@material-ui/icons/CropFree';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ContactIcon from '@material-ui/icons/Contacts';
import {Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, SpacnuseTheme,Typograph, withStyles,useTheme, unit, Typography} from '@material-ui/core/';
import {withRouter, BrowserRouter} from "react-router-dom";
import Clockv1 from "../Clock.js"
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import {SideNav, Flex} from '@airbus/components-react'
import { PinDropSharp } from '@material-ui/icons';


const drawerWidth = "8rem";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: "1.2rem",
    color: "white"
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerOpen: {
    marginLeft: -drawerWidth,
    background: '#00205b',
    width: "drawerWidth", 
    top:"3.5rem",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerHeader: {       //header for drawer (menu & chervon)
    display: 'flex',
    paddingLeft:"4.5rem",
    alignItems: 'right',
    // padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  
  drawerClose: {
    marginLeft: '0px',
    minWidth:'4rem',
    display: 'flex',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#00205b',
    top: theme.spacing.unit * 10,
    overflowX: 'hidden',
    width: theme.spacing(1) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5.4) ,
      
    },
  },
  
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    
  },
  tabsIcon:
  {
    color:'white',

  },
  tabsText:
  {
    color:'white', 
    fontSize:'0.67em', 
    fontWeight: 'bold',
  },
  
  tabsIconSelected:
  {
    color:'#FBCA33', //from horizon theme color ...we should use theme context in the future

  },
  tabsTextSelected:
  {
    color:'#FBCA33',  //from horizon theme color ...we should use theme context in the future
    fontSize:'0.67em', 
    fontWeight: 'bold'
  },
  version:
  {

    align:"center",
    alignItems:"flex-end",
    color: 'white',
   
  },
  space:
  {

    align:"center",
    alignItems:"flex-end",
    color: 'white',
    marginTop:"36rem",
    bottom: "0",
    justifyContent: 'flex-end',
    // marginBottom: 36
  },





}));

function Sidebar(props) {   // set initial state for drawer

 const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const lastClicked=props.selectedTab
  //const [lastClicked,setLastClicked] = React.useState(props.selectedTab);

  const setSelectedTab=(index)=>
  {
    props.setTab(index);
  }
  
  const handleDrawerOpen = () => {
    setOpen(true);
    props.drawerState(true); //send drawer state to parent so we can scale content div
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.drawerState(false);//send drawer state to parent so we can scale content div
  };

  const onClickHandler=(e,index)=>{ //for sidebar options routing
        setSelectedTab(index);
}

const LinkBehavior = React.forwardRef((props, ref) => (

  <RouterLink  ref={ref} to={"/" +props.role+"/changelog"} {...props} />
));


  return (
    <div className={classes.root}>
           {/* <div >  */}

      <SideNav
      style={open ? {width:"8rem"}: {width: "1rem"}}
        permanent theme="dark"
        className={clsx(classes.drawer, {    //handle open and close states
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({   //handle open and close states
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerHeader}>
{!open && <IconButton  // icon button for opening of drawer
             color="inherit"
             aria-label="open drawer"
             onClick={handleDrawerOpen}
             className={clsx(classes.menuButton, {
               [classes.hide]: open,
             })}
           >
             <MenuIcon  />
           </IconButton>}
          {open &&<IconButton  onClick={handleDrawerClose} // icon button for closing of drawer
          > 
            {<ChevronLeftIcon style={{color:'white'}}/>}
          </IconButton>}
        </div> 
        
        <BrowserRouter>
        <List > 
          <ListItem  alignItems='center' id={"upload"} button key={'Map'} onClick= {e=>onClickHandler(e,0)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==0?classes.tabsIconSelected:classes.tabsIcon} ><MapIcon /></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}}disableTypography = {true } className={lastClicked==0?classes.tabsTextSelected:classes.tabsText} primary={'Map'}/>}                                      
          </ListItem>

          <ListItem  alignItems='center' id={"upload"} button key={'QR'} onClick= {e=>onClickHandler(e,1)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==1?classes.tabsIconSelected:classes.tabsIcon} ><QRIcon /></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}}disableTypography = {true } className={lastClicked==1?classes.tabsTextSelected:classes.tabsText} primary={'Asset QR'}/>}                                      
          </ListItem>

          {/* <ListItem  alignItems='center' id={"upload"} button key={'Upload File'} onClick= {e=>onClickHandler(e,0)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==0?classes.tabsIconSelected:classes.tabsIcon} ><UploadIcon /></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}}disableTypography = {true } className={lastClicked==0?classes.tabsTextSelected:classes.tabsText} primary={'Upload File'}/>}                                      
          </ListItem>
          <ListItem  id={'download'} alignItems="center" button key={'Download File'} onClick= {e=>onClickHandler(e,1)}>
            <ListItemIcon style={{minWidth:'25px'}}   className={lastClicked==1?classes.tabsIconSelected:classes.tabsIcon} ><DownloadIcon/></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}} textAlign='center' disableTypography = {true } className={lastClicked==1?classes.tabsTextSelected:classes.tabsText} primary={'Download File'}/>}                                      
          </ListItem>
          <ListItem  id={'contacts'} alignItems="center" button key={'Contact List'} onClick= {e=>onClickHandler(e,2)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==2?classes.tabsIconSelected:classes.tabsIcon} ><ContactIcon/></ListItemIcon>         
            { open && <ListItemText style={{paddingTop:3, paddingLeft:"10px"}} disableTypography = {true } className={lastClicked==2?classes.tabsTextSelected:classes.tabsText} primary={'Contact List'}/> }                                     
          </ListItem> */}

 <ListItem  alignItems='center' id={"upload"} button key={'TestForm'} onClick= {e=>onClickHandler(e,2)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==2?classes.tabsIconSelected:classes.tabsIcon} ><UploadIcon /></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}}disableTypography = {true } className={lastClicked==2?classes.tabsTextSelected:classes.tabsText} primary={'Asset Defect'}/>}                                      
          </ListItem> 


          </List>
        </BrowserRouter>
            {/* <Typography  variant={"caption"} align={"center"} className={classes.space }> {props.time} </Typography >  */}
        <Clockv1/>
  
        <Typography variant={"caption"} align={"center"}> <Link style={{color:'white'}} role ={props.role} component={LinkBehavior}>{process.env.REACT_APP_VERSION}</Link></Typography> {/*so we know what version we are on */}
      
      </SideNav>


    </div>
  );
}

export default withRouter(Sidebar);