 import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';



const styles = theme => ({
  root: {
    flexGrow: 1,
    // justifyContent: "center",
    // alignItems: "center",
  },
  card: {
    flexGrow: 1,
    minWidth: 250,
    minHeight: 250,
    maxWidth:250,
    maxHeight:250,
  },
  grid: {
    minWidth: 275,
    maxWidth:280,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:"75%",
  },

  div: { 
    display: 'block'
  }
  });


class Changelog extends React.Component
{
    state=
    {
      isExpanded:[false,false],

    }

    handleExpandClick=(e,index)=>{ 
      this.props.setTab(index);
        // if(index===0) this.props.history.push( "/"+this.props.role+"/uploadFile");
        // if(index===1) this.props.history.push( "/"+this.props.role+"/downloadFile");
        // if(index===2) this.props.history.push( "/"+this.props.role+"/contacts");
    }

    //sets drawer state. In this case it's an array to cater to multiple drawers with a single function
    toggleDrawer=(e,index)=>
    {
      console.log(index)
      var expanded=this.state.isExpanded.slice();
      expanded[index]=!this.state.isExpanded[index];
      this.setState({isExpanded:expanded});
    }
  
checkFile=()=>

{
//this.props.checkNewFiles();

}


    render()
    {
        const {classes} = this.props;
      
        return(
          <div className={classes.root}>
            <h2 style={{marginLeft:'1rem'}}> {process.env.REACT_APP_VERSION} Changelog </h2> 
            <div>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.0.2b</strong> </p>
<p style={{marginLeft:'1rem'}}>
- QR Scan to report asset defects (create / update asset defects)
<br />- Added "source" and changed naming conventions in Asset info table 
<br />- Bug fixes for form fields validations 
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.0.2a</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Implemented map for region based asset tracking
<br />- Implemented react-data-grid for list of assets and asset status
<br />- QR Scan to check in / check out assets
<br />- Implemented Responsive UI for mobile based on Airbus components library
<br />- Fixed Regex in code for browser support on IOS devices 
</p>
</div>
   
</div>
        )
    }
}
export default withRouter(withStyles(styles)(Changelog));