import axios from 'axios'
//axios.defaults.headers.get['x-api-key'] = "Xkp6bvLSxa3QAAImGAK308Js9fIKRdd336p7KsCz"; //only affects get requests
axios.defaults.headers.get['Content-Type'] = 'application/json';//only affects get requests

export const getTrackers =(rowcount,iotownerid,callback)=>
{
    var retData=""
    axios.get(`${process.env.REACT_APP_BASE_URL}/sigfox?`,{
        headers:{
            'x-api-key':process.env.REACT_APP_BASE_API_KEY
        },
        params : {
            iotdevicenameid:"13",  //get by iotdevicename id - workaround for GET
            order:"desc",
            containgps:"1",
            row:rowcount,
            join:"1",
            iotownerid:iotownerid //5
        }
    }).then(res => {
            retData=res.data;
             //console.log(res.data)
            if(callback!=null)  callback(retData)

        }).catch(err => {
            console.log(err.response); });

}
export const getTrackersTest =(rowcount,iotownerid,callback)=>
{
    var retData=""
    axios.get("http://localhost:8081/devices",{
    }).then(res => {
            retData=res.data;
             //console.log(res.data)
            if(callback!=null)  callback(retData)

        }).catch(err => {
            console.log(err.response); });

}

//retrieve the zone data to display zone boundaries
export const getZoneData=(rowcount,iotownerid,callback)=>
{
    var retData=""
    axios.get(`${process.env.REACT_APP_BASE_URL}/owner_region?`,{
        headers:{
            'x-api-key':process.env.REACT_APP_BASE_API_KEY
        },
        params : {
            order:"desc",
            join:"1",
            iotownerid:iotownerid,
            row:rowcount

        }
    }).then(res => {
            retData=res.data;
            //  console.log(res.data)
             var regexWhitspace=/\s/g; 
             var newGeojson=[]
             res.data.forEach(item => {

                var coordinates=[]
                coordinates=item.iotregioncoordinates.split(','); //split string by comma into individual array elements
                for(var i=0;i< coordinates.length;i++)
                {
                  if(item.iotregiongeometrytype==null )break;
            
                  coordinates[i]= coordinates[i].split(regexWhitspace);
                   coordinates[i][0]= coordinates[i][0]*1; //apparently *1 is the fastest way to cast to number performance wise
                  coordinates[i][1]= coordinates[i][1]*1;
            
                //   //Leaflet uses ISO defined Lat Lng, whereas GeoJSON uses Lng Lat. we need to swap these values.
                //   var temp = coordinates[i][0];
                //   coordinates[i][0]=coordinates[i][1];
                //   coordinates[i][1]=temp;
                }
                var coordinateArray=[]; 
                coordinateArray.push(coordinates.reverse());
              newGeojson.push(coordinateArray)
            }
            
             )
    
// console.log(newGeojson)
            if(callback!=null)  callback(retData,newGeojson); 

        }).catch(err => {
            console.log(err.response); });
}


export const getAssets =(rowcount,iotownerid,callback)=>
{
    var retData=""
    axios.get(`${process.env.REACT_APP_BASE_URL}/asset?`,{
        headers:{
            'x-api-key':process.env.REACT_APP_BASE_API_KEY
        },
        params : {
            order:"asc",
            row:rowcount,
            // join:"1",
            iotownerid:iotownerid, //5
            // iotassetid:"iotownerid" //5
            // iotassetserialno:"A-1253A"
        }
    }).then(res => {
            retData=res.data;
            if(callback!=null)  callback(retData)

        }).catch(err => {
            console.log(err.response); });

}

export const getAssetDefect = (callback) => {
    var retData=[]

      axios.get(`${process.env.REACT_APP_BASE_URL}/asset_defect`, {
        headers: {
          "x-api-key": process.env.REACT_APP_BASE_API_KEY,
        },
        params: {
          // join:1
        },
      })
      .then((res) => {
        // this.setState({ assetDefectData: res.data, dialogOpen: false, hasScanned:true}, () => {this.processDefectData(this.state.assetDefectData)});
        retData=res.data;
        if(callback!=null)  callback(retData)
        // console.log(retData)

        return 1;
      })
      .catch((err) => {
        console.log(err.response);
        // var todayDate=  format(new Date(), 'yyyy-MM-dd kk:mm:ss')
        // this.setState({createDefect:true,showSubmitDefectButton:true,assetDefectLastReportedDate:todayDate,hasScanned:false})
        return 1;
      });
    }
  