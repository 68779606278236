import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'; //we need this to display the default circles around clusters
import  'leaflet.markercluster/dist/leaflet.markercluster-src.js';
import 'leaflet.fullscreen/Control.FullScreen.js'
import 'leaflet.fullscreen/Control.FullScreen.css'
import {defaultIcon,selectedIcon,movingIcon,unavailableIcon} from './CustomMapIcons.js'
import {getTrackers,getZoneData} from '../../components/DownloadManager'
import { parseSync } from '@babel/core';
//section is used to fix react issues with displaying leaflet icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const style = {
    width: "100%",
    height: "500px", //original 700px
   marginTop:'3rem'
  };
  var locs=L.markerClusterGroup({ chunkedLoading: true ,spiderfyOnMaxZoom:true,disableClusteringAtZoom:17});

  var baselayers={}
  var overlays={
    "GPS":locs,
  }

class Map extends React.Component {

  state=
  {
    markerList:[], 
    testvar:[]

    //array of marker objects 
    //selectedMarker:null, //selected marker object
    //prevIcon:null, // previous icon type to reset colour/icon correctly
  }

  componentDidMount() {
    this.mapTileList();
    this.initializeMap()

      //  this.getZoneData();
    

  }

  getZoneData=()=>
  {
    getZoneData(100,2, (res)=>
      {

      console.log(res)
      this.setState({airportLayer:res[0].iotregioncoordinates},()=>{ 
         var xxx =this.state.airportLayer.split(",").reverse()
      console.log(xxx)
      var psy=[]
      var regexWhitspace=/\s/g; //search for ALL whitespace(tab and enter included)

     xxx.forEach(item => {

      
        var coordinates=[]
        coordinates=item.split(','); //split string by comma into individual array elements
        for(var i=0;i< coordinates.length;i++)
        {
      
    
          coordinates[i]= coordinates[i].split(regexWhitspace);
          coordinates[i][0]= coordinates[i][0]*1; //apparently *1 is the fastest way to cast to number performance wise
          coordinates[i][1]= coordinates[i][1]*1;
    
          //Leaflet uses ISO defined Lat Lng, whereas GeoJSON uses Lng Lat. we need to swap these values.
          var temp = coordinates[i][0];
          coordinates[i][0]=coordinates[i][1];
          coordinates[i][1]=temp;
        }
      
   
      
         psy.push(...coordinates)
      }

      
      )
 console.log(psy)
 this.setState({test:psy})
    })
  })
}


  componentDidUpdate(prevProps,prevState)
  
  { 
    // console.log(prevProps.testvar,prevState.test,this.props.testvar,this.state.test)
        // check if data has changed
        if (this.props.trackerData !== prevProps.trackerData) {
          this.updateTrackers(this.props.trackerData);
        }
        //if we select a tracker elsewhere, pan to it on map
        if (this.props.selectedTracker !== prevProps.selectedTracker && this.props.selectedTracker != null ) {
          //this.clickToPan(this.props.selectedTracker.latLng,this.map.getZoom());
          this.clickToPan(this.props.selectedTracker.latLng,17);
          //this.updateSelectedTracker(this.props.selectedTracker);

          //console.log(this.props.selectedTracker)
        }
        //if zoneData has an update (rare but QOL check)
        if (this.props.geoZoneData !== prevProps.geoZoneData ) {


          this.CreateZone(this.props.geoZoneData);
        }
        // if (this.props.selectedZone !== prevProps.selectedZone && this.props.selectedZone != null ) {
        //   var coords={lat:this.props.selectedZone.geometry.coordinates[0][0][1],lng:this.props.selectedZone.geometry.coordinates[0][0][0]}
        //   this.clicktopan(coords,this.map.getZoom());
        // }

   
  }

  render() {
    return <div id="map" style={style}  />;

  }

  initializeMap=()=>
  {
      // var xxx = this.state.test
      // console.log(xxx)
      // console.log(xxx[0].iotregioncoordinates.split(",").reverse().join())
      // var zzz=xxx[0].iotregioncoordinates.split(",").reverse()
      // console.log(zzz)
      // var ggg =[]
      // for (var i=0; i<zzz.length; i++ ){
      //   ggg.push([zzz[i]])
        
      //    }
      //    console.log(ggg)

      var GeoZones = new L.FeatureGroup();// FeatureGroup is to store editable layers   
      // var polyShape=null;  //polygon holder to pass to main map for upload/save
      GeoZones.id=1;//assign layer an id for easy reference later
      Object.assign(overlays,{"GeoZones":GeoZones}); //add featuregroup to control so it can be toggled on and off
  
      this.map = L.map('map', {
        //drawControl:true,
        center: [25.08376,121.2443],
        zoom: 15, 
        layers: [baselayers.osmMap
        ],
        closePopupOnClick:true,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topleft'
        },
        touchZoom:true,
      });
      this.layer = L.layerGroup().addTo(this.map);
      this.updateTrackers(this.props.trackerData);
      this.map.addLayer(locs);
      L.control.layers(baselayers,overlays).addTo(this.map); //ads the selectable control box in the top right of the map
      this.map.options.maxZoom=19
    
      var polygon = L.polygon([
        
        [
          25.082975,
          121.25655400000001
        ],
        [
          25.084782999999998,
          121.25488
        ],
        [
          25.085118,
          121.255175
        ],
        [
          25.085511000000004,
          121.25496600000001
        ],
        [
          25.08570600000001,
          121.254838
        ],
        [
          25.085855999999993,
          121.25467700000002
        ],
        [
          25.085871,
          121.25454200000001
        ],
        [
          25.085714999999993,
          121.254349
        ],
        [
          25.08560900000001,
          121.254205
        ],
        [
          25.085559999999994,
          121.254129
        ],
        [
          25.085953999999997,
          121.253754
        ],
        [
          25.086342000000005,
          121.253373
        ],
        [
          25.087042,
          121.25270200000001
        ],
        [
          25.087022406256445,
          121.25272393226625
        ],
        [
          25.087736583811672,
          121.25211238861083
        ],
        [
          25.08857707314697,
          121.25149548053741
        ],
        [
          25.088756830232384,
          121.25138282775877
        ],
        [
          25.088829999999994,
          121.25134500000001
        ],
        [
          25.088931999999993,
          121.251367
        ],
        [
          25.089024,
          121.25138300000002
        ],
        [
          25.089067999999997,
          121.25082500000002
        ],
        [
          25.08907299999999,
          121.250305
        ],
        [
          25.08964599999999,
          121.25021900000002
        ],
        [
          25.08972800000001,
          121.250283
        ],
        [
          25.09014099999999,
          121.25003600000001
        ],
        [
          25.090276999999993,
          121.250466
        ],
        [
          25.09042300000001,
          121.25057300000002
        ],
        [
          25.090608000000003,
          121.25082500000002
        ],
        [
          25.090943000000006,
          121.25149
        ],
        [
          25.093071,
          121.25038499999998
        ],
        [
          25.093463999999997,
          121.250229
        ],
        [
          25.09430499999999,
          121.24996100000001
        ],
        [
          25.095154999999995,
          121.24974700000001
        ],
        [
          25.098390000000013,
          121.249366
        ],
        [
          25.099095,
          121.24929100000001
        ],
        [
          25.100086,
          121.24908699999999
        ],
        [
          25.100818999999998,
          121.24889400000002
        ],
        [
          25.101548000000005,
          121.248658
        ],
        [
          25.101280999999993,
          121.24832
        ],
        [
          25.101033000000005,
          121.24800300000001
        ],
        [
          25.101563000000013,
          121.24752000000001
        ],
        [
          25.102096999999986,
          121.247011
        ],
        [
          25.103073000000006,
          121.24612600000002
        ],
        [
          25.10250999999999,
          121.24541200000002
        ],
        [
          25.10194200000001,
          121.244693
        ],
        [
          25.100533000000013,
          121.242891
        ],
        [
          25.099071000000006,
          121.24103499999998
        ],
        [
          25.097656999999987,
          121.23920600000001
        ],
        [
          25.096209000000012,
          121.237376
        ],
        [
          25.096301999999998,
          121.23688800000001
        ],
        [
          25.096383999999993,
          121.2364
        ],
        [
          25.096175000000006,
          121.23601400000001
        ],
        [
          25.09595700000001,
          121.23563299999999
        ],
        [
          25.094523000000013,
          121.23525700000002
        ],
        [
          25.093124000000003,
          121.23490300000002
        ],
        [
          25.092449000000006,
          121.23405
        ],
        [
          25.091818000000004,
          121.233208
        ],
        [
          25.09067599999999,
          121.231771
        ],
        [
          25.088922000000004,
          121.229539
        ],
        [
          25.086619000000006,
          121.226621
        ],
        [
          25.085434000000003,
          121.225129
        ],
        [
          25.082397000000004,
          121.221331
        ],
        [
          25.079050000000002,
          121.21700800000002
        ],
        [
          25.079380000000008,
          121.216686
        ],
        [
          25.079701000000007,
          121.216353
        ],
        [
          25.076173,
          121.211879
        ],
        [
          25.074784000000005,
          121.210839
        ],
        [
          25.074385000000003,
          121.210039
        ],
        [
          25.074006000000004,
          121.209219
        ],
        [
          25.071752,
          121.20631100000001
        ],
        [
          25.071271000000007,
          121.20678800000002
        ],
        [
          25.07077000000001,
          121.20727100000002
        ],
        [
          25.069764,
          121.208226
        ],
        [
          25.069133,
          121.207448
        ],
        [
          25.069069000000002,
          121.207684
        ],
        [
          25.068992,
          121.20794700000002
        ],
        [
          25.068578999999996,
          121.20858
        ],
        [
          25.067996000000008,
          121.20882199999998
        ],
        [
          25.067387999999994,
          121.209068
        ],
        [
          25.06687799999999,
          121.20955600000002
        ],
        [
          25.066357999999994,
          121.209991
        ],
        [
          25.06517200000001,
          121.210189
        ],
        [
          25.066182999999988,
          121.211547
        ],
        [
          25.066086000000013,
          121.21168599999999
        ],
        [
          25.064857000000014,
          121.212968
        ],
        [
          25.06584799999999,
          121.213891
        ],
        [
          25.066863000000005,
          121.21481900000002
        ],
        [
          25.06815100000001,
          121.215999
        ],
        [
          25.067364,
          121.216739
        ],
        [
          25.066572,
          121.21748
        ],
        [
          25.064987999999996,
          121.218923
        ],
        [
          25.06471599999999,
          121.21911000000001
        ],
        [
          25.06443899999999,
          121.219229
        ],
        [
          25.064152,
          121.219298
        ],
        [
          25.062883999999997,
          121.219379
        ],
        [
          25.06243199999999,
          121.21950700000001
        ],
        [
          25.06169799999999,
          121.21998500000001
        ],
        [
          25.061207000000007,
          121.22013
        ],
        [
          25.060391000000013,
          121.22010799999998
        ],
        [
          25.060283999999996,
          121.219706
        ],
        [
          25.05844199999999,
          121.21651900000002
        ],
        [
          25.058272000000006,
          121.21647700000001
        ],
        [
          25.057766999999995,
          121.21697500000002
        ],
        [
          25.05735399999999,
          121.217383
        ],
        [
          25.057261000000004,
          121.21742600000002
        ],
        [
          25.056984,
          121.217689
        ],
        [
          25.056575999999993,
          121.217163
        ],
        [
          25.056212000000002,
          121.21792
        ],
        [
          25.05528800000001,
          121.221315
        ],
        [
          25.056833999999995,
          121.223284
        ],
        [
          25.058350000000008,
          121.225215
        ],
        [
          25.06229599999999,
          121.230215
        ],
        [
          25.062918000000003,
          121.229657
        ],
        [
          25.06444400000001,
          121.23158800000002
        ],
        [
          25.066387000000006,
          121.234056
        ],
        [
          25.068331000000008,
          121.236502
        ],
        [
          25.072126,
          121.241341
        ],
        [
          25.07564800000001,
          121.24586800000002
        ],
        [
          25.075362,
          121.246136
        ],
        [
          25.07503099999999,
          121.24644800000002
        ],
        [
          25.07626099999999,
          121.24800300000001
        ],
        [
          25.077475000000007,
          121.249559
        ],
        [
          25.082975,
          121.25655400000001
        ]
        
      ]).addTo(this.map);
 

  //   var polygon = L.polygon([
  //  [xxx]
  // ]).addTo(this.map);
    // console.log(data.features[0].geometry.coordinates.map=(item)=>{return console.log(item)})
    // var xxx = (this.props.geoZoneData.features[0].geometry.coordinates)
    // console.log(this.state.test)
 


    //  console.log(this.state.airportLayer.features[0].geometry.coordinates.reverse())

  }

  //creates tracker objects (markers) on map
  updateTrackers=(trackerData)=>
  {
    locs.clearLayers();
    //this.layer.clearLayers();
    var markerList=[]
    trackerData.forEach(tracker => {
      
      var markerIcon=null;
      
      if(tracker.iotsigfoxrawdata.state==="ON_THE_MOVE")markerIcon=L.marker(tracker.latLng, { title: tracker.serial ,icon:movingIcon }).addTo(locs);
      else if(tracker.iotsigfoxrawdata.state==="UNAVAILABLE")markerIcon=L.marker(tracker.latLng, { title: tracker.serial ,icon:unavailableIcon }).addTo(locs);
      else markerIcon = L.marker(tracker.latLng, { title: tracker.serial ,icon:defaultIcon }).addTo(locs);

      //markerIcon.on("click",this.updateSelectedTracker.bind(null,tracker,markerIcon,true)); //to bind to onclick event in leaflet marker

      markerIcon.on("click", this.props.setSelectedTracker.bind(null,tracker)); //the null is because of the events "e" value.
      markerIcon.bindPopup('<h5>'+'<b>ID:</b> '+tracker.serial+'</h5>'
      +'<h5>'+'<b>Asset:</b> '+tracker.assetName+'</h5>'
      +'<h5>'+'<b>Last Seen:</b> '+tracker.time+'</h5>'
      +'<h5>'+'<b>Source:</b> '+tracker.iotsigfoxrawdata.source+'</h5>'
      )
      var markerIconObj={};
      markerIconObj.serial=tracker.serial; //we use serial for ID purposes
      markerIconObj.marker=markerIcon;
      markerList.push(markerIconObj)
    }
    );
    this.setState({markerList:markerList});
  }

  test=(tracker)=>
  {

  }

//updates selected tracker/marker tracker=actual tracker data; marker= physical object displayed on leaflet map; 
  updateSelectedTracker=(tracker,markerIcon)=>
  {
      //this.map.closePopup();
      var obj =this.state.markerList.find(x => x.serial === tracker.serial)
      obj.marker.openPopup();
      //console.log(tracker)
      //this.props.setSelectedTracker(tracker);

    //THIS REGION IS USED TO CHANGE MARKER COLOUR ONCLICK TO INDICATE SELECTION. DISABLED SINCE POPUPS HAVE BEEN ENABLED ON CLICK
    //if(this.state.selectedMarker)this.state.selectedMarker.setIcon(this.state.prevIcon); //reset the previous marker object colour 
    //this.setState({selectedMarker:markerIcon}) //update the selected marker object
    //this.setState({prevIcon:markerIcon.options.icon}) //store the previous icon type so we can reset when it is not "selected"
    //markerIcon.setIcon(selectedIcon) //change the current selected marker colour icon
  }

  ///pan to given marker, and zoom in accordingly
  clickToPan(loc ,zoom)
  {
    //this.map.setView(loc, zoom, {animate: true,duration:0.25});
    var latlng=[[loc.lat,loc.lng]];
    this.map.flyToBounds(latlng,{maxZoom:zoom, duration:0.25}); //centers on an arrat of latlng, can be used to keep a group in view
    setTimeout(()=>{ this.updateSelectedTracker(this.props.selectedTracker); }, 300);
  }

  ///add received geojson data to map as leaflet.draw data
  CreateZone(geoZoneData)
  {
  
    var geojsonLayer = L.geoJson(geoZoneData,
    {
      onEachFeature:function(feature,layer)
      {
        //layer.bindPopup('<h1>Zone: '+feature.properties.Name+'</h1><p>Description: '+feature.properties.Description+'</p>');
      }
    }
      
      );
    overlays.GeoZones.addLayer(geojsonLayer);
  }

  ///initialize different map layers so we can change map tiles 
  mapTileList()
  {
    var osmMap=L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
     '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      maxNativeZoom:20,
      maxZoom: 21
      })

      // var googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
      // maxZoom: 21,
      // subdomains:['mt0','mt1','mt2','mt3']
      //  });
      // var googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
      //   maxZoom: 21,
      //   subdomains:['mt0','mt1','mt2','mt3']
      //  });

    //   var hybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
    //     maxZoom: 20,
    //     subdomains:['mt0','mt1','mt2','mt3']
    // });

      baselayers.osmMap=osmMap;
      //baselayers.googleStreets=googleStreets;
      // baselayers.googleSat=googleSat;
      //baselayers.Hybrid=hybrid;
  }  
}

export default Map;