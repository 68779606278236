import React, { Component } from "react";
import {IconButton, Menu, MenuItem} from '@material-ui/core';
import {BrowserRouter, withRouter} from 'react-router-dom';
import{deleteCookie} from '../Cookie'
import {Avatar, } from '@airbus/components-react';
import MenuIcon from '@material-ui/icons/Menu';

function SimpleMenu(props) {  // Dropdown list for account mgmt options at the side
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (e,index) => {
    setAnchorEl(null);
    switch(index)
    {
      case 0:
        props.history.push( "/"+props.role+"/account");
        return;
      case 1: 
        deleteCookie(["RoleToken","LoginToken"]);
        props.history.push( "/login");
        return;
      default:
        return;
    }
  };
  
  return (
    
    <div>
       <IconButton style={{height:"2rem"}}   onClick = {handleClick}> 
       <MenuIcon style={{height:"2.2rem", color:"white"}}  />
       {/* <Avatar></Avatar> */}
      </IconButton> 
      <BrowserRouter> 
      <Menu
     id="simple-menu"
     anchorEl={anchorEl}
     keepMounted
     open={Boolean(anchorEl)}
     onClose={handleClose}
      >
        <MenuItem onClick={e=>handleClose(e,0)}>Profile</MenuItem>
        <MenuItem onClick={e=>handleClose(e,1)}>Logout</MenuItem>
      </Menu>
      </BrowserRouter> 
    </div>
  );
}

export default withRouter(SimpleMenu)