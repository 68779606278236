import React from 'react';
import SimpleMenu from './Menu';
import SimpleCard from './Card';
import { makeStyles } from '@material-ui/core/styles';
import "./style.css";
import{Typography} from "@material-ui/core"
import {
    AppBar,
    AppTitle,
    ThemeProvider,
    Toolbar,
  } from 'horizon-components-react';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    header:
    {
      maxHeight:'3.2rem'
    }
 
  }));
   
  export default function Header(props) {     //main header component to be called to be rendered
    const classes = useStyles();
  
    return(
        <div className={classes.root}>

     <ThemeProvider theme= {"airbus"}>

     <AppBar sticky className={classes.header}>
    
         <Toolbar disableGutters  >
         <SimpleCard role={props.role} setTab={props.setTab} ></SimpleCard>   
         
             <AppTitle style = {{flexGrow:1}}>  
              {/* <div className ="text1"> AIRFRAME SERVICES</div> */}
              {/* <div className ="text1"> AIRFRAME SERVICES {"("+process.env.NODE_ENV.toUpperCase()+ ")"}</div> */}
              {/* <div className ="text1" >HoF IoT Platform</div> */}
              <div className ="text1" > IoT</div>

          </AppTitle>

          <div style={{paddingRight:".5rem"}}>                 
          <Typography>{props.username}</Typography>    
          </div>
                                     
          <div>                 
          <SimpleMenu role={props.role} newFiles = {props.newFiles}></SimpleMenu>    
          </div>
                                          
         </Toolbar>
        </AppBar>
    </ThemeProvider>
</div>

        );
    
}
